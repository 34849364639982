import * as React from 'react'

import { Link, useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation } from '@apollo/client'

import { Dialog, Transition, Listbox } from '@headlessui/react'

// import { mergeDeep, mergeDeepWith, is, Map, List, fromJS } from 'immutable'
import { fromJS } from 'immutable'

import { LinkIcon, DocumentTextIcon } from '@heroicons/react/outline'

import {
	ChevronLeftIcon,
	ChevronRightIcon,
	ExclamationIcon,
	PlusIcon,
	CheckIcon,
	SelectorIcon,
} from '@heroicons/react/solid'

// import EditEpisodeReadyState from './EditEpisodeReadyState'
import EpisodeItem from '../EpisodeItem'
import InputImageUpload from '../InputImageUpload'
import InputText from '../InputText'
import InputTextArea from '../InputTextArea'
import InputAudioUpload from '../InputAudioUpload'
import InputDateTime from '../InputDateTime'
import InputToggle from '../InputToggle'

import SlideOver from '../SlideOver'

import { loader } from 'graphql.macro'
const VIEWER_EPISODES = loader('./ViewerEpisodes.graphql')
const VIEWER_EPISODE = loader('./GetEpisode.graphql')
const CREATE_EPISODE = loader('./CreateEpisode.graphql')
const UPDATE_EPISODE = loader('./UpdateEpisode.graphql')

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function ContentEpisodes() {
	let { episodeId } = useParams()
	// console.log('episodeId', episodeId)
	let navigate = useNavigate()
	// const [editEpisodeDialog, setEditEpisodeDialog] = React.useState(true);

	function closeEditEpisodeDialog() {
		// do some navigation
		navigate('../../episodes')
	}

	const something = ['a', 'b', 'c']
	const somethingElse = ['a', 'b', 'c']
	const object1 = { a: 1, b: 2, something: something }
	const object2 = { a: 1, b: 2, something: somethingElse }

	// console.log('object1 === object2', object1 === object2)
	// console.log(
	// 	'object1.equals(object2)',
	// 	fromJS(object1).equals(fromJS(object2))
	// )

	// const showEditEpisodeDialog = episodeId ? true : false
	// console.log('showEditEpisodeDialog', showEditEpisodeDialog)

	return (
		<div className='px-4 py-6 sm:px-6 lg:px-8 overflow-y-auto'>
			{' '}
			{/* h-full */}
			<div className='mb-6'>
				<Link
					to='new'
					type='button'
					className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
				>
					Lag ny episode
				</Link>
			</div>
			{/* <button
        type="button"
        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Edit
      </button>
      <button
        type="button"
        className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Publish
      </button> */}
			{/* TODO: Implement that when clicking an item in the list, we show it loading in the list */}
			<div className='overflow-y-auto'>
				<EpisodeList />
			</div>
			<PaginationPlaceholder />
			{episodeId ? (
				<EditEpisodeSlideOver
					key={episodeId}
					episodeId={episodeId}
					// open={showEditEpisodeDialog}
					closeEditEpisodeDialog={closeEditEpisodeDialog}
				/>
			) : null}
		</div>
	)
}

export function EpisodeList() {
	const { loading, error, data } = useQuery(VIEWER_EPISODES)

	if (loading) return <p>Loading...</p>
	if (error) {
		// console.log(error)
		return <p>Error :(</p>
	}

	let episodes = []
	if (data) {
		// console.log('data', data)
		episodes = data.viewer.organization.episodes.nodes
	}

	return (
		<div className=''>
			<ul className=''>
				{episodes.map((episode) => (
					<li key={episode.id} className='mb-2'>
						<Link to={`${episode.id}`}>
							<EpisodeItem episode={episode} />
						</Link>
					</li>
				))}
			</ul>
		</div>
	)
}

function EditEpisodeSlideOver({ closeEditEpisodeDialog, episodeId }) {
	// const [openChooseEpisodes, setOpenChooseEpisodes] = React.useState(false)
	const [episodeOpenedOnce, setEpisodeOpenedOnce] = React.useState(false)
	const [episodeOpen, setEpisodeOpen] = React.useState(false)
	const [episodeData, setEpisodeData] = React.useState({})

	const shouldSkipQuery = episodeId === 'new'
	const { loading, error, data } = useQuery(VIEWER_EPISODE, {
		variables: {
			episodeId: episodeId,
		},
		skip: shouldSkipQuery,
	})

	const [
		createEpisode,
		{
			// data: createEpisodeData,
			loading: createEpisodeLoading,
			// error: createEpisodeError,
		},
	] = useMutation(CREATE_EPISODE, {
		refetchQueries: [VIEWER_EPISODE, VIEWER_EPISODES],
	})

	const [
		updateEpisode,
		{
			// data: updateEpisodeData,
			loading: updateEpisodeLoading,
			// error: updateEpisodeError,
		},
	] = useMutation(UPDATE_EPISODE, {
		// refetchQueries: [VIEWER_EPISODE, VIEWER_EPISODES],
	})

	// console.log('loading', loading)
	// console.log('error', error)
	// console.log('data', data)

	// fix this later, is causing rendering bug
	React.useEffect(() => {
		if (episodeId === 'new') {
			setEpisodeOpen(true)
		}

		if (data && episodeOpenedOnce === false) {
			setEpisodeOpenedOnce(true)
			setEpisodeOpen(true)
			// let episode = data?.viewer?.organization?.episode
			let episode = data?.episode
			if (episode) {
				setEpisodeData({
					...episode,
				})
			}
		}
	}, [data, episodeId, episodeOpenedOnce])

	// console.log('data', data, 'episodeOpen', episodeOpen)

	// we do this due to animation
	function handleClose() {
		setEpisodeOpen(false)

		window.setTimeout(() => {
			closeEditEpisodeDialog()
		}, 500)
	}

	if (loading) {
		return (
			<Transition.Root show={true} as={React.Fragment}>
				<Dialog
					as='div'
					// initialFocus={refDiv}
					className='fixed inset-0 overflow-hidden'
					// onClose={setOpen}
					onClose={() => null}
				>
					<Dialog.Overlay className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />

					<div className='fixed inset-y-0 right-0 pl-10 max-w-full flex items-center justify-center sm:pl-16'>
						<div className={`w-32 h-32`}>
							<div className='h-full flex flex-col bg-white shadow-xl'>
								Loading...
							</div>
						</div>
					</div>
				</Dialog>
			</Transition.Root>
		)
	}

	if (error) {
		return <div>Error :/</div>
	}

	// console.log('render SlideOverTest2')

	// this will all be replace with ApolloGraphQL when we get that far, so will handle more cases then
	let episode = null
	if (episodeId !== 'new') {
		// episode = episodes.find((episode) => episode.id === episodeId)
		// episode = data?.viewer?.organization?.episode
		episode = data?.episode
	} else {
		episode = {}
	}

	// console.log('episodeOpen', episodeOpen)

	return (
		<SlideOver open={episodeOpen} setOpen={handleClose} size='max-w-4xl'>
			<SlideOver.Title handleClose={handleClose}>
				{episode?.title
					? `Redigerer episoden "${episode?.title}"`
					: 'Legg til ny episode'}
			</SlideOver.Title>
			<SlideOver.Content>
				<EditEpisodeForm
					episode={episode}
					episodeData={episodeData}
					setEpisodeData={setEpisodeData}
				/>
			</SlideOver.Content>
			<SlideOver.Actions>
				<EditEpisodeActions
					episodeId={episodeId}
					createEpisode={createEpisode}
					episodeData={episodeData}
					handleClose={handleClose}
					episode={episode}
					updateEpisode={updateEpisode}
					createEpisodeLoading={createEpisodeLoading}
					updateEpisodeLoading={updateEpisodeLoading}
				/>
				<TempEditEpisodeReadyState />
			</SlideOver.Actions>
		</SlideOver>
	)
}

function EditEpisodeActions({
	episodeId,
	createEpisode,
	episodeData,
	handleClose,
	episode,
	updateEpisode,
	createEpisodeLoading,
	updateEpisodeLoading,
}) {
	return (
		<div className='flex justify-start'>
			<button
				type='submit'
				className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
				onClick={async (event) => {
					if (episodeId === 'new') {
						event.preventDefault()
						await createEpisode({ variables: { input: episodeData } })
						handleClose(false)
					} else {
						// update with ID
						event.preventDefault()

						if (episodeData?.id) {
							const editableKeys = [
								'approved',
								'coverImage',
								'title',
								'description',
								'audioUrl',
								'publishDateTime',
								'unpublishDateTime',
								'duration',
								// 'attachments',
								// quiz
							]

							// only update data that changed and is an editableKey
							const updatedEpisodeInputData = editableKeys.reduce(
								(previousValue, currentValue) => {
									// console.log(
									// 	'currentValue',
									// 	currentValue,
									// 	'episodeData?.[currentValue]',
									// 	episodeData?.[currentValue],
									// 	'episode?.[currentValue]',
									// 	episode?.[currentValue],
									// 	'episodeData?.[currentValue] != episode?.[currentValue]',
									// 	episodeData?.[currentValue] != episode?.[currentValue]
									// )

									if (
										currentValue in episodeData &&
										episodeData?.[currentValue] !== episode?.[currentValue]
									) {
										// console.log('setting: ', currentValue)
										previousValue[currentValue] = episodeData?.[currentValue]
									}

									return previousValue
								},
								{}
							)

							if (
								'attachments' in episodeData &&
								episodeData?.attachments?.length > 0
							) {
								updatedEpisodeInputData.attachments =
									episodeData.attachments.map((attachment) => {
										return Object.keys(attachment)
											.filter((key) => ['type', 'title', 'url'].includes(key))
											.reduce((cur, key) => {
												return Object.assign(cur, {
													[key]: attachment[key],
												})
											}, {})
									})
							}

							// console.log('episode', episode)
							// console.log('episodeData', episodeData)
							// console.log('updatedEpisodeInputData', updatedEpisodeInputData)

							// no changed data, so need to save over network
							if (Object.keys(updatedEpisodeInputData).length > 0) {
								await updateEpisode({
									variables: {
										episodeId: episodeData.id,
										input: updatedEpisodeInputData,
									},
								})
								handleClose(false)
							} else {
								handleClose(false)
							}
						}
					}
				}}
			>
				{createEpisodeLoading || updateEpisodeLoading ? 'Lagrer...' : 'Save'}
			</button>
			<button
				type='button'
				className='ml-4 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
				onClick={() => handleClose(false)}
			>
				Cancel
			</button>
		</div>
	)
}

function TempEditEpisodeReadyState() {
	const [validationOpen, setValidationOpen] = React.useState(true)

	return (
		<div className='place-self-end absolute right-8'>
			{/* <div className='validation'>sdfdsfdsfs</div> */}
			<div>
				{validationOpen ? (
					<div className='rounded-md bg-red-50 p-4'>
						<div className='flex'>
							<div className='flex-shrink-0'>
								<ExclamationIcon
									className='h-5 w-5 text-red-400'
									aria-hidden='true'
								/>
							</div>
							<div className='ml-3'>
								<h3 className='text-sm font-medium text-red-800'>
									Episoden er ufullstendig og kan ikke publiseres
								</h3>
								<div className='mt-2 text-sm text-red-700'>
									<p>Følgende informasjon er ufullstendig:</p>
									<ul className='list-disc pl-5 pt-1 space-y-1'>
										<li>Lydfil må lastes opp</li>
										<li>Bilde må lastes opp</li>
										<li>Beskrivelse må fylles ut</li>
									</ul>
								</div>
								<button
									className='mt-2 text-sm text-red-900'
									onClick={() => setValidationOpen(false)}
								>
									Vis mindre informasjon
								</button>
							</div>
						</div>
					</div>
				) : (
					<div className='rounded-md bg-red-50 p-4'>
						<div className='flex'>
							<div className='flex-shrink-0'>
								<ExclamationIcon
									className='h-5 w-5 text-red-400'
									aria-hidden='true'
								/>
							</div>
							<div className='ml-3'>
								<h3 className='text-sm font-medium text-red-800'>
									Episoden er ufullstendig og kan ikke publiseres
								</h3>
								<button
									className='mt-2 text-sm text-red-900'
									onClick={() => setValidationOpen(true)}
								>
									Vis mer informasjon
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	)
}
/*
<div className="absolute inset-0 px-4 sm:px-6">
                      <div
                        className="h-full border-2 border-dashed border-gray-200"
                        aria-hidden="true"
                      >
                        <button onClick={() => setSecondDialog(true)}>
                          {episodeId}
                        </button>
                      </div>
                    </div>
*/

function EditEpisodeForm({ episode, setEpisodeData, episodeData }) {
	/*
  Episode State (Explain each state)
    Set published state button
  Image *
  Title *
  Description *
  Audio (mp3 upload) *
    Duration (auto generated from mp3 upload)
  Publish date *
  Unpublish date
  Attachments
    Type: Link / Document (both opens browser)
    title
    url / upload (on document)
  Quiz (Y/N)
    Intro Card *
      Title
      Description
      Estimated time
    Add question
      Question Card
        Question
        Add alternative button
        Alternatives (min 3, max 10)*
          Alternative
          Correct (Y/N)
  Save button
  */

	const [quizEnabled, setQuizEnabled] = React.useState(false)

	// console.log('editEpisodeForm')

	return (
		<div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mb-48'>
			<div className='sm:col-span-6'>
				<InputToggle
					id='approved'
					value={episodeData?.approved}
					label='Publiserings Status'
					helperText='En episode må være godkjent før den kan gå live.'
					toggleLabel={episodeData.approved ? 'Godkjent' : 'Ikke godkjent'}
					toggleHelperText={
						episodeData.approved
							? '(publiseres på publiseringsdatoen)'
							: '(vil ikke bli publisert)'
					}
					onChange={(inputApproved) => {
						setEpisodeData({
							...episodeData,
							approved: inputApproved,
						})
					}}
				/>
			</div>
			<div className='sm:col-span-6'>
				<InputImageUpload
					imageUrl={episodeData.coverImage}
					episodeData={episodeData}
					setEpisodeData={setEpisodeData}
				/>
			</div>
			<div className='sm:col-span-6'>
				<InputText
					id='title'
					label='Tittel'
					helperText='Tittel er påkrevd for å publisere en episode'
					required
					value={episodeData?.title ? episodeData.title : ''}
					onChange={(event) => {
						setEpisodeData({
							...episodeData,
							title: event.target.value,
						})
					}}
				/>
			</div>
			<div className='sm:col-span-6'>
				<InputTextArea
					id='description'
					label='Beskrivelse'
					required
					helperText='Beskrivelsen er påkrevd for å publisere en episode'
					value={episodeData?.description ? episodeData.description : ''}
					onChange={(event) => {
						setEpisodeData({
							...episodeData,
							description: event.target.value,
						})
					}}
				/>
			</div>
			<div className='sm:col-span-6'>
				<InputAudioUpload
					audioUrl={episodeData.audioUrl}
					episodeData={episodeData}
					setEpisodeData={setEpisodeData}
				/>
			</div>
			<div className='sm:col-span-6'>
				<InputText
					id='duration'
					label='Varighet'
					helperText='Episodens varighet i sekunder. Varighet er påkrevd for å publisere en episode'
					required
					value={episodeData?.duration ? episodeData.duration : ''}
					onChange={(event) => {
						setEpisodeData({
							...episodeData,
							duration: parseInt(event.target.value),
						})
					}}
				/>
			</div>
			<div className='sm:col-span-6'>
				<InputDateTime
					id='publishDateTime'
					onChange={(dateTime) => {
						setEpisodeData({
							...episodeData,
							publishDateTime: dateTime,
						})
					}}
					value={episodeData?.publishDateTime}
					label='Publiseringstidspunkt'
					helperText='Publiseringstidspunkt er påkrevd for å publisere en episode.'
					required
				/>
			</div>
			<div className='sm:col-span-6'>
				<InputDateTime
					id='unpublishDateTime'
					label='Avpubliseringstidspunkt'
					onChange={(dateTime) => {
						setEpisodeData({
							...episodeData,
							unpublishDateTime: dateTime,
						})
					}}
					value={episodeData?.unpublishDateTime}
					helperText='Dato hvor episoden automatisk skal fjernes fra Talker.'
				/>
			</div>
			<div className='sm:col-span-6'>
				<span className='block text-sm font-medium text-gray-700'>Vedlegg</span>
				<div className='mt-4'>
					<button
						type='button'
						className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
						onClick={() => {
							// console.log('episodeState', episodeState)
							const previousAttachments =
								episodeData?.attachments?.length > 0
									? [...episodeData.attachments]
									: []
							setEpisodeData({
								...episodeData,
								attachments: [
									...previousAttachments,
									{
										type: 'LINK',
										title: '',
										url: '',
									},
								],
							})
						}}
					>
						<PlusIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
						Nytt vedlegg
					</button>
				</div>
				<AttachmentsEditor
					attachments={episodeData?.attachments}
					episodeState={episodeData}
					setEpisodeState={setEpisodeData}
				/>

				{/* <div>Attachments </div>
        <div>Legg til ny attachment</div>
        <div>
          Attachments grid (type [link, document], title, url), remove button
        </div> */}
			</div>
			<div className='sm:col-span-6'>
				<InputToggle
					id='quizEnabled'
					value={quizEnabled}
					label='Quiz'
					// helperText='En episode må være godkjent før den kan gå live.'
					toggleLabel={quizEnabled ? 'Har quiz' : 'Har ikke quiz'}
					toggleHelperText={
						quizEnabled
							? '(denne episoden skal ha quiz som brukeren kan utføre etter de har hørt episoden)'
							: '(denne episoden skal ikke ha quiz som brukeren kan utføre)'
					}
					onChange={(inputQuizEnabled) => {
						setQuizEnabled(inputQuizEnabled)
					}}
				/>

				{/*
        Question Card
        Question
        Add alternative button
        Alternatives (min 3, max 10)*
          Alternative
          Correct (Y/N)
        */}
				{quizEnabled ? (
					<React.Fragment>
						<div className='mt-6'>
							<button
								type='button'
								className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
								onClick={() => {}}
							>
								<PlusIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
								Nytt spørsmåls kort
							</button>
						</div>
						{/* {episode?.quiz?.cards?.length > 0 ? null : (
              <div className="text-center border-2 border-gray-300 border-dashed rounded-lg my-4 py-4">
                <h3 className="mt-2 text-sm font-medium text-gray-900">
                  Ingen vedlegg
                </h3>
                <p className="mt-1 text-sm text-gray-500">
                  Opprette et nytt vedlegg ved å bruke knappen over.
                </p>
              </div>
            )} */}
						<ul className='divide-y divide-gray-200 my-2 border-2 border-dashed border-gray-300 rounded-lg px-4'>
							{episodeData?.quiz?.cards?.map((card) => (
								<li key={card.email} className='py-4 flex'>
									<div className='flex-grow'>
										<div className='flex'>
											<div className='w-40'>
												{card.type === 'intro' ? 'Introkort' : 'Spørsmålskort'}
											</div>
											<div className='ml-3 flex-grow'>
												<label
													htmlFor='street-address'
													className='block text-sm font-medium text-gray-700'
												>
													Titel *
												</label>
												<div className='mt-1'>
													<input
														type='text'
														name='title'
														id='title'
														value={episode.title}
														autoComplete='off'
														className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
													/>
												</div>
												{/* <p className="mt-2 text-sm text-gray-500">
                    Tittel er påkrevd for å publisere en episode
                  </p> */}
											</div>
										</div>
										<div className='flex-grow mt-2'>
											<label
												htmlFor='street-address'
												className='block text-sm font-medium text-gray-700'
											>
												Url *
											</label>
											<div className='mt-1'>
												<input
													type='text'
													name='title'
													id='title'
													value={episode.url}
													autoComplete='off'
													className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
												/>
											</div>
											{/* <p className="mt-2 text-sm text-gray-500">
                    Tittel er påkrevd for å publisere en episode
                  </p> */}
										</div>
									</div>
									<div className='pl-8 ml-auto'>
										<span className='block text-sm font-medium text-gray-700'>
											Handlinger
										</span>
										<button
											type='button'
											className='bg-white py-2 px-4 mt-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
											onClick={() => null}
										>
											Fjern spørsmålskortet
										</button>
									</div>
								</li>
							))}
						</ul>
					</React.Fragment>
				) : null}
			</div>
		</div>
	)
}

function AttachmentsEditor({ attachments, episodeState, setEpisodeState }) {
	if (attachments?.length > 0) {
		return (
			<ul className='divide-y divide-gray-400 my-2 border-2 border-dashed border-gray-300 rounded-lg px-4'>
				{attachments?.map((attachment, attachmentIndex) => (
					<li key={attachmentIndex} className='py-6 flex'>
						<div className='flex-grow'>
							<div className='flex'>
								<div className='w-40'>
									<AttachmentTypeDropdown
										value={attachment.type}
										onChange={(value) => {
											const attachmentsClone = [...episodeState.attachments]
											const attachmentClone = {
												...attachmentsClone[attachmentIndex],
												type: value,
											}
											attachmentsClone[attachmentIndex] = attachmentClone
											setEpisodeState({
												...episodeState,
												attachments: attachmentsClone,
											})
										}}
									/>
								</div>
								<div className='ml-3 flex-grow'>
									<label
										htmlFor='street-address'
										className='block text-sm font-medium text-gray-700'
									>
										Titel *
									</label>
									<div className='mt-1'>
										<input
											type='text'
											name='title'
											id='title'
											value={attachment.title}
											onChange={(event) => {
												const attachmentsClone = [...episodeState.attachments]
												const attachmentClone = {
													...attachmentsClone[attachmentIndex],
													title: event.target.value,
												}
												attachmentsClone[attachmentIndex] = attachmentClone
												setEpisodeState({
													...episodeState,
													attachments: attachmentsClone,
												})
											}}
											autoComplete='off'
											className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
										/>
									</div>
									{/* <p className="mt-2 text-sm text-gray-500">
            Tittel er påkrevd for å publisere en episode
          </p> */}
								</div>
							</div>
							<div className='flex-grow mt-2'>
								<label
									htmlFor='street-address'
									className='block text-sm font-medium text-gray-700'
								>
									Url *
								</label>
								<div className='mt-1'>
									<input
										type='text'
										name='title'
										id='title'
										value={attachment.url}
										onChange={(event) => {
											const attachmentsClone = [...episodeState.attachments]
											const attachmentClone = {
												...attachmentsClone[attachmentIndex],
												url: event.target.value,
											}
											attachmentsClone[attachmentIndex] = attachmentClone
											setEpisodeState({
												...episodeState,
												attachments: attachmentsClone,
											})
										}}
										autoComplete='off'
										className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
									/>
								</div>
								{/* <p className="mt-2 text-sm text-gray-500">
            Tittel er påkrevd for å publisere en episode
          </p> */}
							</div>
						</div>
						<div className='pl-8 ml-auto'>
							<span className='block text-sm font-medium text-gray-700'>
								Handlinger
							</span>
							<button
								type='button'
								className='bg-white py-2 px-4 mt-1 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
								onClick={() => {
									const attachmentsClone = [...episodeState.attachments]
									attachmentsClone.splice(attachmentIndex, 1)
									setEpisodeState({
										...episodeState,
										attachments: attachmentsClone,
									})
								}}
							>
								Fjern vedlegg
							</button>
						</div>
					</li>
				))}
			</ul>
		)
	}

	return (
		<div className='text-center border-2 border-gray-300 border-dashed rounded-lg my-4 py-4'>
			<h3 className='mt-2 text-sm font-medium text-gray-900'>Ingen vedlegg</h3>
			<p className='mt-1 text-sm text-gray-500'>
				Opprett et nytt vedlegg for å komme i gang med vedlegg.
			</p>
		</div>
	)
}

const validAttachmentTypes = [
	{
		title: 'Link',
		value: 'LINK',
		icon: 'link',
	},
	{
		title: 'Dokument',
		value: 'DOCUMENT',
		icon: 'document',
	},
]

function AttachmentIcons({ icon }) {
	if (icon === 'link') {
		return <LinkIcon className='h-6 w-6' />
	}

	if (icon === 'document') {
		return <DocumentTextIcon className='h-6 w-6' />
	}
}

function AttachmentTypeDropdown({ value, onChange }) {
	const [selected, setSelected] = React.useState(
		validAttachmentTypes.find((validType) => validType.value === value)
	)

	function setSelectedAttachmentType(updatedValue) {
		setSelected(updatedValue)
		if (typeof onChange === 'function') {
			onChange(updatedValue.value)
		} else {
			console.warn('onChange was not set on AttachmentTypeDropdown')
		}
	}

	return (
		<Listbox value={selected} onChange={setSelectedAttachmentType}>
			{({ open }) => (
				<React.Fragment>
					<Listbox.Label className='block text-sm font-medium text-gray-700'>
						Type
					</Listbox.Label>
					<div className='mt-1 relative'>
						<Listbox.Button className='relative w-full bg-white border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-1.5 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'>
							<span className='flex items-center'>
								<AttachmentIcons icon={selected.icon} />
								<span className='ml-3 block truncate'>{selected.title}</span>
							</span>
							<span className='ml-3 absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none'>
								<SelectorIcon
									className='h-5 w-5 text-gray-400'
									aria-hidden='true'
								/>
							</span>
						</Listbox.Button>

						<Transition
							show={open}
							as={React.Fragment}
							leave='transition ease-in duration-100'
							leaveFrom='opacity-100'
							leaveTo='opacity-0'
						>
							<Listbox.Options className='absolute z-10 mt-1 w-full bg-white shadow-lg max-h-56 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm'>
								{validAttachmentTypes.map((attachmentType) => (
									<Listbox.Option
										key={attachmentType.value}
										className={({ active }) =>
											classNames(
												active ? 'text-white bg-indigo-600' : 'text-gray-900',
												'cursor-default select-none relative py-2 pl-3 pr-9'
											)
										}
										value={attachmentType}
									>
										{({ selected, active }) => (
											<>
												<div className='flex items-center'>
													<AttachmentIcons icon={attachmentType.icon} />
													<span
														className={classNames(
															selected ? 'font-semibold' : 'font-normal',
															'ml-3 block truncate'
														)}
													>
														{attachmentType.title}
													</span>
												</div>

												{selected ? (
													<span
														className={classNames(
															active ? 'text-white' : 'text-indigo-600',
															'absolute inset-y-0 right-0 flex items-center pr-4'
														)}
													>
														<CheckIcon className='h-5 w-5' aria-hidden='true' />
													</span>
												) : null}
											</>
										)}
									</Listbox.Option>
								))}
							</Listbox.Options>
						</Transition>
					</div>
				</React.Fragment>
			)}
		</Listbox>
	)
}

function PaginationPlaceholder() {
	return (
		<div className='bg-white px-4 py-3 my-6 flex items-center justify-between border-t border-gray-200 sm:px-6'>
			<div className='flex-1 flex justify-between sm:hidden'>
				<a
					href='/temp'
					className='relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
				>
					Previous
				</a>
				<a
					href='/temp'
					className='ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
				>
					Next
				</a>
			</div>
			<div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
				<div>
					<p className='text-sm text-gray-700'>
						Viser <span className='font-medium'>1</span> til{' '}
						<span className='font-medium'>10</span> av{' '}
						<span className='font-medium'>97</span> episoder
					</p>
				</div>
				<div>
					<nav
						className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'
						aria-label='Pagination'
					>
						<a
							href='/temp'
							className='relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
						>
							<span className='sr-only'>Neste</span>
							<ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
						</a>
						{/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
						<a
							href='/temp'
							aria-current='page'
							className='z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
						>
							1
						</a>
						<a
							href='/temp'
							className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
						>
							2
						</a>
						<a
							href='/temp'
							className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium'
						>
							3
						</a>
						<span className='relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700'>
							...
						</span>
						<a
							href='/temp'
							className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium'
						>
							8
						</a>
						<a
							href='/temp'
							className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
						>
							9
						</a>
						<a
							href='/temp'
							className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
						>
							10
						</a>
						<a
							href='/temp'
							className='relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
						>
							<span className='sr-only'>Next</span>
							<ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
						</a>
					</nav>
				</div>
			</div>
		</div>
	)
}
