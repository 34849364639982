import * as React from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'

import firebaseApp from '../utils/firebase.js'
import { getAuth } from 'firebase/auth'
import { getDatabase, ref, onValue } from 'firebase/database'
import { useNavigate } from 'react-router-dom'

export default function User() {
	const [user, loading /*, error*/] = useAuthState(getAuth(firebaseApp))
	const [token, setToken] = React.useState('')
	const [claims, setClaims] = React.useState('')

	let navigate = useNavigate()

	// // console.log("user", user);
	// React.useEffect(() => {
	// 	return getAuth(firebaseApp).onAuthStateChanged(function (user) {
	// 		if (user) {
	// 			user.getIdToken().then(function (idToken) {
	// 				// token = idToken
	// 				setToken(idToken)
	// 				setClaims(idToken.claims)
	// 			})
	// 		} else {
	// 			// localStorage.removeItem('token')
	// 			// token = ''
	// 			// setToken("");
	// 		}
	// 	})
	// }, [])

	React.useEffect(() => {
		return getAuth(firebaseApp).onIdTokenChanged(function (user) {
			if (user) {
				user.getIdToken().then(function (idToken) {
					// token = idToken
					setToken(idToken)
				})
				user.getIdTokenResult().then((idTokenResult) => {
					// Confirm the user is an Admin.
					if (!!idTokenResult.claims) {
						// Show admin UI.
						// showAdminUI()
						setClaims(idTokenResult.claims)
					} else {
						// Show regular user UI.
						// showRegularUI()
					}
				})
			} else {
				// localStorage.removeItem('token')
				// token = ''
				// setToken("");
			}
		})
	}, [])

	// React.useEffect(() => {
	// 	getAuth(firebaseApp)
	// 		.currentUser.getIdTokenResult()
	// 		.then((idTokenResult) => {
	// 			// Confirm the user is an Admin.
	// 			if (!!idTokenResult.claims) {
	// 				// Show admin UI.
	// 				// showAdminUI()
	// 				setClaims(idTokenResult.claims)
	// 			} else {
	// 				// Show regular user UI.
	// 				// showRegularUI()
	// 			}
	// 		})
	// 		.catch((error) => {
	// 			console.log(error)
	// 		})
	// }, [])

	React.useEffect(() => {
		if (user) {
			// const metadataRef = ref('metadata/' + user.uid + '/refreshTime')
			// const db = getDatabase()
			// const recentPostsRef = query(ref(db, 'metadata'), limitToLast(100))
			// // console.log('-------------', recentPostsRef)
			// onValue(recentPostsRef, (snapshot) => {
			// 	const data = snapshot.val()
			// 	// updateStarCount(postElement, data);
			// 	console.log('data', data)
			// })
			const db = getDatabase()
			const metadataRef = ref(db, 'metadata/' + user.uid + '/refreshTime')
			onValue(metadataRef, (snapshot) => {
				const data = snapshot.val()
				// updateStarCount(postElement, data);
				console.log('---------- data for id ' + user.uid + ': ', data)
				// user.getIdToken(true);
			})
		}
		// firebase
		// 	.auth()
		// 	.currentUser.getIdTokenResult()
		// 	.then((idTokenResult) => {
		// 		// Confirm the user is an Admin.
		// 		if (!!idTokenResult.claims) {
		// 			// Show admin UI.
		// 			// showAdminUI()
		// 			setClaims(idTokenResult.claims)
		// 		} else {
		// 			// Show regular user UI.
		// 			// showRegularUI()
		// 		}
		// 	})
		// 	.catch((error) => {
		// 		console.log(error)
		// 	})
	}, [user])

	if (loading) {
		return <div>Loading, please wait</div>
	}

	return (
		<div className='p-5'>
			<div className='py-2'>
				<div className='font-medium'>Email:</div>
				<div>
					{user.email} (verified: {`${user.emailVerified}`})
				</div>
			</div>
			<div className='py-2'>
				<div className='font-medium'>UID:</div>
				<div>{user.uid}</div>
			</div>
			<div className='py-2'>
				<div className='font-medium'>Token:</div>
				<div className='break-all w-2/4'>{token}</div>
			</div>
			<div className='py-2'>
				<button
					className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
					onClick={() => {
						// getAuth(firebaseApp).getIdToken(true)
						getAuth(firebaseApp).currentUser.getIdToken(true)
					}}
				>
					Refresh token
				</button>
			</div>
			<div className='py-2'>
				<button
					className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
					onClick={async () => {
						await getAuth(firebaseApp).signOut()
						navigate('/')
					}}
				>
					Sign out
				</button>
			</div>
			<div className='py-2'>
				<div className='font-medium'>User:</div>
				<div>
					<pre>{JSON.stringify(user, null, 2)}</pre>
				</div>
			</div>
			<div className='py-2'>
				<div className='font-medium'>Claims:</div>
				<div>
					<pre>{JSON.stringify(claims, null, 2)}</pre>
				</div>
			</div>
		</div>
	)
}
