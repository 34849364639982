import * as React from 'react'

import { useAuthState } from 'react-firebase-hooks/auth'
import { getAuth } from 'firebase/auth'

import Login from '../Login'
import AuthedRoot from '../AuthedRoot'

function App() {
	const [user, loading /*, error*/] = useAuthState(getAuth())

	// change this to be a suspense?
	if (loading) {
		return null
	}

	return <React.Fragment>{user ? <AuthedRoot /> : <Login />}</React.Fragment>
}

export default App
