import * as React from 'react'

export default function InputDateTime({
	id,
	onChange,
	value,
	label,
	helperText,
	required,
}) {
	const date = value
	let jsDate
	let validDate = false
	if (date) {
		jsDate = new Date(date)
		validDate = jsDate && !isNaN(jsDate.getTime())
	}
	// console.log('date', date, jsDate)
	const getFullYear = validDate ? jsDate.getFullYear() : null
	const getMonth = validDate ? `00${jsDate.getMonth() + 1}`.slice(-2) : null
	const getDate = validDate ? `00${jsDate.getDate()}`.slice(-2) : null
	// const getTime = validDate ? jsDate.getTime() : null
	const getHours = validDate ? `00${jsDate.getHours()}`.slice(-2) : null
	const getMinutes = validDate ? `00${jsDate.getMinutes()}`.slice(-2) : null
	// const getSeconds = validDate ? jsDate.getSeconds() : null
	// const getTimezoneOffset = validDate ? jsDate.getTimezoneOffset() : null

	const [dateControl, setDateControl] = React.useState(
		validDate ? `${getFullYear}-${getMonth}-${getDate}` : ''
	)
	const [timeControl, setTimeControl] = React.useState(
		validDate ? `${getHours}:${getMinutes}` : ''
	)
	// const dateControl = validDate ? `${getFullYear}-${getMonth}-${getDate}` : ''
	// const timeControl = validDate ? `${getHours}:${getMinutes}` : ''

	// React.useEffect(() => {
	// 	const newDate = new Date(`${dateControl}T${timeControl}`)
	// 	const epochTime = newDate.getTime()
	// 	if (epochTime && !isNaN(epochTime)) {
	// 		onChange(epochTime)
	// 	} else {
	// 		onChange(null)
	// 	}
	// }, [dateControl, timeControl])

	// const calculatedDate = new Date(`${dateControl}T${timeControl}`)

	function onDateChange(event) {
		// console.log('event', event, event.target.value)
		// console.log('asNumber', event.target.valueAsNumber)
		// console.log('valueAsDate', event.target.valueAsDate)
		const eventDateControl = event.target.value
		const newDate = new Date(`${eventDateControl}T${timeControl}`)
		const epochTime = newDate.getTime()
		if (epochTime && !isNaN(epochTime)) {
			onChange(epochTime)
		} else {
			onChange(null)
		}
		setDateControl(event.target.value)
	}

	function onTimeChange(event) {
		// console.log('date', event, event.target.value)
		// console.log('asNumber', event.target.valueAsNumber)
		// console.log('valueAsDate', event.target.valueAsDate)
		const eventTimeControl = event.target.value
		const newDate = new Date(`${dateControl}T${eventTimeControl}`)
		const epochTime = newDate.getTime()
		console.log('epoch', newDate, epochTime)
		if (epochTime && !isNaN(epochTime)) {
			onChange(epochTime)
		} else {
			onChange(null)
		}
		setTimeControl(event.target.value)
	}

	return (
		<React.Fragment>
			<label htmlFor={id} className='block text-sm font-medium text-gray-700'>
				{label ? label : ''}
				{required ? ' *' : ''}
			</label>
			<input
				id={id}
				name={id}
				type='date'
				value={dateControl}
				onChange={onDateChange}
				className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-48 mr-4 sm:text-sm border-gray-300 rounded-md'
			/>
			<input
				type='time'
				value={timeControl}
				onChange={onTimeChange}
				className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 w-28 sm:text-sm border-gray-300 rounded-md'
			/>
			<p className='mt-2 text-sm text-gray-500'>{helperText}</p>
		</React.Fragment>
	)
}

/*
<React.Fragment>
			<label htmlFor={id} className='block text-sm font-medium text-gray-700'>

			</label>
			<div className='mt-1'>
				<input
					id={id}
					name={id}
					type='text'
					autoComplete='off'
					className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
					value={value}
					onChange={onChange}
				/>
			</div>
			<p className='mt-2 text-sm text-gray-500'>
				{helperText ? helperText : ''}
			</p>
		</React.Fragment>
*/
