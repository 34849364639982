function TalkerLogo({ ...restProps }) {
	return (
		<svg
			width={1343}
			height={529}
			viewBox={'0 0 1343 529'}
			xmlns='http://www.w3.org/2000/svg'
			{...restProps}
		>
			<g fillRule='nonzero' fill='none'>
				<rect fill='#FFF' y={344.6} width={10.11} height={135} rx={5.055} />
				<rect
					fill='#FFF'
					x={63.43}
					y={322.1}
					width={10.11}
					height={180}
					rx={5.055}
				/>
				<rect
					fill='#FFF'
					x={126.85}
					y={312.1}
					width={10.11}
					height={200}
					rx={5.055}
				/>
				<rect
					fill='#FFF'
					x={190.28}
					y={297.1}
					width={10.11}
					height={230}
					rx={5.055}
				/>
				<rect
					fill='#FFF'
					x={253.7}
					y={327.1}
					width={10.11}
					height={170}
					rx={5.055}
				/>
				<rect
					fill='#FFF'
					x={317.13}
					y={349.6}
					width={10.11}
					height={125}
					rx={5.055}
				/>
				<rect
					fill='#FFF'
					x={380.56}
					y={369.6}
					width={10.11}
					height={85}
					rx={5.055}
				/>
				<rect
					fill='#FFF'
					x={443.98}
					y={339.6}
					width={10.11}
					height={145}
					rx={5.055}
				/>
				<rect
					fill='#FFF'
					x={507.41}
					y={329.73}
					width={10.11}
					height={164.73}
					rx={5.055}
				/>
				<rect
					fill='#FFF'
					x={570.83}
					y={310.6}
					width={10.11}
					height={202.99}
					rx={5.055}
				/>
				<rect
					fill='#FFF'
					x={634.26}
					y={296.14}
					width={10.11}
					height={231.91}
					rx={5.055}
				/>
				<rect
					fill='#FFF'
					x={697.68}
					y={354.21}
					width={10.11}
					height={115.78}
					rx={5.055}
				/>
				<rect
					fill='#FFF'
					x={761.11}
					y={334.57}
					width={10.11}
					height={155.05}
					rx={5.055}
				/>
				<rect
					fill='#FFF'
					x={824.54}
					y={349.27}
					width={10.11}
					height={125.65}
					rx={5.055}
				/>
				<rect
					fill='#FFF'
					x={887.96}
					y={368.42}
					width={10.11}
					height={87.36}
					rx={5.055}
				/>
				<rect
					fill='#BCBCBC'
					x={951.39}
					y={344.39}
					width={10.11}
					height={135.42}
					rx={5.055}
				/>
				<rect
					fill='#BCBCBC'
					x={1014.81}
					y={320.3}
					width={10.11}
					height={183.59}
					rx={5.055}
				/>
				<rect
					fill='#BCBCBC'
					x={1078.24}
					y={310.59}
					width={10.11}
					height={203.02}
					rx={5.055}
				/>
				<rect
					fill='#BCBCBC'
					x={1141.67}
					y={296.13}
					width={10.11}
					height={231.93}
					rx={5.055}
				/>
				<rect
					fill='#BCBCBC'
					x={1205.09}
					y={324.89}
					width={10.11}
					height={174.41}
					rx={5.055}
				/>
				<rect
					fill='#BCBCBC'
					x={1268.52}
					y={349.03}
					width={10.11}
					height={126.13}
					rx={5.055}
				/>
				<rect
					fill='#BCBCBC'
					x={1331.94}
					y={368.28}
					width={10.11}
					height={87.64}
					rx={5.055}
				/>
				<path
					d='M202.34 211.28V12.56h-69.76c-3 0-5.29-1.65-5.29-4.63V6.28c0-3 2.31-4.63 5.29-4.63H285c3.31 0 5.3 1.65 5.3 4.63v1.65c0 3-2 4.63-5.3 4.63h-70.42v198.72c0 3.3-2 5.29-5 5.29h-2.65c-2.93 0-4.59-1.99-4.59-5.29ZM473.8 216.57h-4c-3.3 0-4-1.33-5.62-4.63L442.72 163h-127.6l-21.52 48.94c-1.65 3.3-2.31 4.63-5.62 4.63h-3.64c-3.3 0-4.63-2.32-3-5.29L373.62 3.63c1-2.31 2.64-3.63 5-3.63h1.32c2.31 0 4 1.32 5 3.63l91.92 207.65c1.57 2.97.26 5.29-3.06 5.29Zm-36-64.48L378.91 18.18l-58.86 133.91H437.8ZM519.76 211.61V6.94c0-3.31 1.65-5.29 4.63-5.29h2.64c3 0 5 2 5 5.29v198.71h107.42c3 0 5 1.66 5 4.63v1.66c0 3-2 4.63-5 4.63H524.39c-2.98 0-4.63-1.99-4.63-4.96ZM830.23 210.94c2.31 2.65.66 5.63-2.65 5.63h-5c-3.3 0-5-1.33-6.94-4l-86.3-107.13-32.36 31.4v74.4c0 3.3-2 5.29-5 5.29h-2.65c-3 0-4.62-2-4.62-5.29V6.94c0-3.31 1.65-5.29 4.62-5.29h2.65c3 0 5 2 5 5.29v116L816.67 5.57c2.65-2.65 4.3-4 7.61-4h4.63c3 0 4.63 3 2.31 5.29l-92.91 90.98 91.92 113.1ZM871.55 211.94V6.28c0-3 1.66-4.63 4.63-4.63h127.3c3 0 5 1.65 5 4.63v1.65c0 3-2 4.63-5 4.63H883.79v89.93h93.9c3 0 5 1.66 5 4.63v1.66c0 3-2 4.63-5 4.63h-93.9v92.24h119.69c3 0 5 1.66 5 4.63v1.66c0 3-2 4.63-5 4.63h-127.3a4.34 4.34 0 0 1-4.63-4.63ZM1197.89 210.94c1.66 3 .33 5.63-2.64 5.63h-3.64c-3.3 0-4.3-1-6-3.64l-49.6-91.92h-69.1v90.27c0 3.3-2 5.29-5 5.29h-2.65c-3 0-4.62-2-4.62-5.29v-205c0-3 1.65-4.63 4.62-4.63h74.07c42.65 0 69.1 23.14 69.1 59.51 0 32.41-21.49 54.56-53.89 58.86l49.35 90.92Zm-130.93-100.51h70.76c30.75 0 52.57-20.17 52.57-49.27 0-28.76-21.49-48.6-54.89-48.6h-68.44v97.87Z'
					fill='#FFF'
				/>
			</g>
		</svg>
	)
}

export default TalkerLogo
