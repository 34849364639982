import * as React from "react";

export default function SampleContent() {
  return (
    <div className="flex-1 flex xl:overflow-hidden">
      {/* Primary column */}
      <section
        aria-labelledby="primary-heading"
        className="min-w-0 flex-1 h-full flex flex-col overflow-hidden lg:order-last"
      >
        Brukere
      </section>

      {/* Secondary column (hidden on smaller screens) */}
      {/* <aside className="hidden lg:block lg:flex-shrink-0 lg:order-first">
              <div className="h-full relative flex flex-col w-96 border-r border-gray-200 bg-white">
                test2
              </div>
            </aside> */}
    </div>
  );
}
