import * as React from 'react'

import {
	ApolloClient,
	InMemoryCache,
	ApolloProvider,
	createHttpLink,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
// import { useAuthState } from "react-firebase-hooks/auth";

import firebaseApp from '../../utils/firebase.js'
import { getAuth } from 'firebase/auth'

import BackOffice from '../BackOffice'
// import SelectOrganization from '../SelectOrganization'

import {
	Routes,
	Route,
	useParams,
	Link,
	Outlet,
	useNavigate,
} from 'react-router-dom'

function AuthedRoot() {
	return (
		<Routes>
			<Route index element={<SelectOrganization />} />
			<Route path='organization/:organizationId/*' element={<Organization />}>
				<Route path='*' element={<OrganizationIndex />} />
			</Route>
			<Route path='*' element={<div>404 - Not found</div>} />
		</Routes>
	)

	// return <SelectOrganization />
	// return <BackOffice />
}

export default AuthedRoot

function SelectOrganization() {
	const [token, setToken] = React.useState('')
	const [claims, setClaims] = React.useState('')
	const [initDone, setInitDone] = React.useState(false)

	let navigate = useNavigate()

	React.useEffect(() => {
		return getAuth(firebaseApp).onIdTokenChanged(function (user) {
			if (user) {
				user.getIdToken().then(function (idToken) {
					// token = idToken
					setToken(idToken)
				})
				user.getIdTokenResult().then((idTokenResult) => {
					// Confirm the user is an Admin.
					if (!!idTokenResult.claims) {
						// Show admin UI.
						// showAdminUI()
						setClaims(idTokenResult.claims)
					} else {
						// Show regular user UI.
						// showRegularUI()
					}
				})
			} else {
				// localStorage.removeItem('token')
				// token = ''
				// setToken("");
			}
		})
	}, [])

	React.useEffect(() => {
		if (claims) {
			setInitDone(true)
			const clientFromTenant = claims?.firebase?.tenant?.split('-')?.[0]

			if (clientFromTenant) {
				navigate(`/organization/${clientFromTenant}`)
			}
		}
	}, [claims])

	if (!initDone) {
		return <div>Loading...</div>
	}

	let organizations = []
	if (claims.organizations) {
		organizations = Object.keys(claims.organizations).map(
			(organizationSlug) => {
				return {
					slug: organizationSlug,
					...claims.organizations[organizationSlug],
				}
			}
		)
	}

	return (
		<div>
			{/* Organizations:
			<pre>{JSON.stringify(organizations, null, 2)}</pre> */}
			<h1>Select organization</h1>
			<ul>
				{organizations.map((organization) => {
					return (
						<li key={organization.slug}>
							<Link to={`organization/${organization.slug}`}>
								{organization.slug}
							</Link>
						</li>
					)
				})}
			</ul>
			{/* <pre>{JSON.stringify(claims, null, 2)}</pre>
			{token} <br /> */}
			<button
				onClick={() => {
					getAuth(firebaseApp).signOut()
				}}
			>
				Signout
			</button>
		</div>
	)
}

function Organization() {
	const { organizationId } = useParams()

	const cache = React.useMemo(() => {
		return new InMemoryCache({
			typePolicies: {
				Query: {
					fields: {
						episode: {
							read(_, { args, toReference }) {
								return toReference({
									__typename: 'Episode',
									id: args.id,
								})
							},
						},
					},
				},
				Episode: {
					fields: {
						attachments: {
							// merge: true,
							merge(existing, incoming, { readField, mergeObjects }) {
								// const merged = existing ? existing.slice(0) : [];
								// const authorNameToIndex = Object.create(null);
								// if (existing) {
								//   existing.forEach((author, index) => {
								//     authorNameToIndex[readField<string>("name", author)] = index;
								//   });
								// }
								// incoming.forEach(author => {
								//   const name = readField<string>("name", author);
								//   const index = authorNameToIndex[name];
								//   if (typeof index === "number") {
								//     // Merge the new author data with the existing author data.
								//     merged[index] = mergeObjects(merged[index], author);
								//   } else {
								//     // First time we've seen this author in this array.
								//     authorNameToIndex[name] = merged.length;
								//     merged.push(author);
								//   }
								// });
								return incoming
							},
						},
						coverImage: {
							// existing, options
							// read(existing, { args, toReference }) {
							// read(existing, options) {
							// 	console.log('existing', existing)
							// 	console.log('options', options)
							// 	let expires
							// 	try {
							// 		expires =
							// 			parseInt(new URL(existing).searchParams.get('Expires')) *
							// 			1000
							// 		console.log(typeof expires)
							// 	} catch (error) {
							// 		return false
							// 	}
							// 	console.log(
							// 		'expires',
							// 		expires,
							// 		expires ? new Date(expires) : null
							// 	)
							// 	console.log('is expired', expires < Date.now())
							// 	debugger
							// 	// return options.canRead(false)
							// 	return existing
							// 	// return toReference({
							// 	// 	__typename: 'Episode',
							// 	// 	id: args.id,
							// 	// })
							// },
						},
					},
				},
			},
			// typePolicies: {
			// 	Query: {
			// 		fields: {
			// 			viewer: {
			// 				keyArgs: false,
			// 				// read(existing = {}, { args: { offset, limit, category }}) {
			// 				//   return existing[category]?.slice(offset, offset + limit);
			// 				// },
			// 				// merge(existing = {}, incoming, { args: { category, offset = 0 }}) {
			// 				//   const merged = existing[category] ? existing[category].slice(0) : [];
			// 				//   for (let i = 0; i < incoming.length; ++i) {
			// 				//     merged[offset + i] = incoming[i];
			// 				//   }
			// 				//   existing[category] = merged;
			// 				//   return existing;
			// 				// },
			// 			},
			// 		},
			// 	},
			// 	User: {
			// 		fields: {
			// 			organization: {
			// 				keyArgs: false,
			// 			},
			// 		},
			// 	},
			// 	Organization: {
			// 		fields: {
			// 			episode: {
			// 				keyArgs: ['id'],
			// 			},
			// 		},
			// 	},
			// },
		})
	}, [organizationId])

	const client = React.useMemo(() => {
		const GRAPHQL_URI = process.env.REACT_APP_GRAPHQL_URI?.replace(
			':organizationId',
			organizationId
		)

		const httpLink = createHttpLink({
			uri: GRAPHQL_URI,
		})

		const authLink = setContext(async (_, { headers }) => {
			const user = getAuth(firebaseApp).currentUser

			if (user) {
				const idToken = await user.getIdToken()

				if (idToken) {
					// we have a user and token, so set authorization header
					return {
						headers: {
							...headers,
							authorization: idToken ? `Bearer ${idToken}` : '',
						},
					}
				}
			}
			// no user just return normal headers
			return {
				headers: {
					...headers,
				},
			}
		})

		return new ApolloClient({
			// uri: "http://localhost:3000/api/graphql",
			link: authLink.concat(httpLink),
			cache: cache,
			connectToDevTools: true,
		})
	}, [organizationId])

	React.useEffect(() => {
		let timer

		function checkExpiredImages() {
			const data = cache.extract()

			const filteredData = Object.keys(data).reduce(
				(previousValue, currentValue) => {
					if (currentValue.split(':')[0] === 'Episode') {
						const episode = data[currentValue]
						if (episode?.coverImage && typeof episode.coverImage === 'string') {
							let expires
							try {
								expires =
									parseInt(
										new URL(episode.coverImage).searchParams.get('Expires')
									) * 1000
								// console.log(typeof expires)
							} catch (error) {
								// return false
							}
							const now = Date.now()
							const willSoonExpire = now < expires - 1000 * 60 * 6 // will expire in less than 6 min, so lets evict it

							if (willSoonExpire) {
								// console.log('evicting ', currentValue, ' from cache')
								cache.evict({
									id: currentValue,
									fieldName: 'coverImage',
									broadcast: false, //we dont broadcast because we dont need to fetch the coverImage url before the user needs the data again
								})
							}
						}
					}
					return previousValue
				},
				[]
			)

			cache.gc()

			timer = setTimeout(() => {
				// console.log('This will run after 1 second!')

				checkExpiredImages()
			}, 1000 * 60 * 5) // run every X minutes (5 right now)
		}

		checkExpiredImages()

		return () => clearTimeout(timer)

		// let timer

		// function checkExpiredImages() {
		// 	debugger
		// 	let expires
		// 	let existing

		// 	try {
		// 		expires = parseInt(new URL(existing).searchParams.get('Expires')) * 1000
		// 		console.log(typeof expires)
		// 	} catch (error) {
		// 		return false
		// 	}

		// 	console.log('expires', expires, expires ? new Date(expires) : null)
		// 	console.log('is expired', expires < Date.now())

		// 	timer = setTimeout(() => {
		// 		console.log('running cache check again')
		// 		checkExpiredImages()
		// 	}, 1000)
		// }

		// checkExpiredImages()

		// return () => {
		// 	console.log('we are done with cache')
		// 	clearTimeout(timer)
		// }
	}, [cache])

	// try to write a cache evicter here?

	return (
		<ApolloProvider client={client}>
			<Outlet />
		</ApolloProvider>
	)
}

function OrganizationIndex() {
	if (1 == 1) {
		return <BackOffice />
	}

	return <div>Could not find organisasjon</div>
}
