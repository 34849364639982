import * as React from 'react'
// import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
// import firebaseApp from '../utils/firebase.js'
import {
	getAuth,
	signInWithEmailAndPassword,
	setPersistence,
	browserLocalPersistence,
	browserSessionPersistence,
	signInWithPopup,
	OAuthProvider,
	connectAuthEmulator,
} from 'firebase/auth'

export default function Login() {
	const [errorMessage, setErrorMessage] = React.useState('')
	const [activeTenant, setActiveTenant] = React.useState('')
	const [rememberMe, setRememberMe] = React.useState(false)
	// const [confirmation, setConfirmation] = React.useState(null)
	const [state, setState] = React.useState({
		email: '',
		password: '',
		phoneNumber: '',
	})

	const handleChange = (event) => {
		setErrorMessage('')
		const { id, value } = event.target
		setState((prevState) => ({
			...prevState,
			[id]: value,
		}))
	}

	const attemptLogin = async () => {
		try {
			const auth = getAuth()
			// connectAuthEmulator(auth, 'http://localhost:9099')

			if (activeTenant) {
				auth.tenantId = activeTenant
			} else {
				auth.tenantId = ''
			}

			var persistence = rememberMe
				? browserLocalPersistence
				: browserSessionPersistence

			await setPersistence(auth, persistence)
			await signInWithEmailAndPassword(auth, state.email, state.password)
		} catch (error) {
			// debugger
			// setLoginButtonDisabled(false);
			// code: "auth/invalid-email"
			if (error.code === 'auth/invalid-email') {
				// message: "The email address is badly formatted.
				setErrorMessage('E-postadressen er ikke gyldig.')
			} else if (error.code === 'auth/wrong-password') {
				// message: "The password is invalid or the user does not have a password."
				setErrorMessage('Passordet er ikke gyldig.')
			} else if (error.code === 'auth/user-not-found') {
				// message: "There is no user record corresponding to this identifier. The user may have been deleted."
				setErrorMessage('Vi fant ingen bruker med denne e-postadressen.')
			} else {
				setErrorMessage(
					'Det skjedde en feil, vennligst prøv igjen senere eller ta kontakt med support for hjelp.'
				)
			}
		}
	}

	return (
		<div className='min-h-screen bg-white flex'>
			<div className='flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24'>
				<div className='mx-auto w-full max-w-sm lg:w-96'>
					<div>
						<img
							className='h-12 w-auto'
							src='https://tailwindui.com/img/logos/workflow-mark-indigo-600.svg'
							alt='Workflow'
						/>
						<h2 className='mt-6 text-3xl font-extrabold text-gray-900'>
							Sign in to your account
						</h2>

						<div>
							<div>Selected tenant: {activeTenant}</div>

							<button
								className='border rounded p-2 mr-2 bg-blue-100'
								onClick={() => setActiveTenant('talker-h43am')}
							>
								Talker
							</button>
							<button
								className='border rounded p-2 bg-blue-100 mr-2'
								onClick={() => setActiveTenant('privatmegleren-okxsn')}
							>
								Privatmegleren
							</button>
							<button
								className='border rounded p-2 bg-gray-100 mt-2'
								onClick={() => setActiveTenant('')}
							>
								Clear tenant
							</button>

							<hr className='mt-2' />
							<button
								className='border rounded p-2 bg-blue-100 mt-2 mr-2'
								onClick={() => {
									const provider = new OAuthProvider('oidc.talker_azure')

									const auth = getAuth()
									if (activeTenant) {
										auth.tenantId = activeTenant
									}

									signInWithPopup(auth, provider)
										.then((result) => {
											// User is signed in.
											const credential =
												OAuthProvider.credentialFromResult(result)
											// This gives you an access token for the OIDC provider. You can use it to directly interact with that provider
											// console.log('credential', credential)
										})
										.catch((error) => {
											// Handle Errors here.
											const errorCode = error.code
											const errorMessage = error.message
											// The email of the user's account used.
											const email = error.customData.email
											// The AuthCredential type that was used.
											const credential =
												OAuthProvider.credentialFromError(error)
											// Handle / display error.
											// ...
											// console.log(errorCode, errorMessage)
										})
								}}
							>
								Start OIDC
							</button>

							<br />
						</div>
						{/*
            <p className="mt-2 text-sm text-gray-600">
              Or{" "}
              <a
                href="#"
                className="font-medium text-indigo-600 hover:text-indigo-500"
              >
                start your 14-day free trial
              </a>
            </p>
            */}
					</div>

					<div className='mt-8'>
						{/*
            <div>
              <div>
                <p className="text-sm font-medium text-gray-700">
                  Sign in with
                </p>

                <div className="mt-1 grid grid-cols-3 gap-3">
                  <div>
                    <a
                      href="#"
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      <span className="sr-only">Sign in with Facebook</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M20 10c0-5.523-4.477-10-10-10S0 4.477 0 10c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V10h2.54V7.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V10h2.773l-.443 2.89h-2.33v6.988C16.343 19.128 20 14.991 20 10z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>

                  <div>
                    <a
                      href="#"
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      <span className="sr-only">Sign in with Twitter</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path d="M6.29 18.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0020 3.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.073 4.073 0 01.8 7.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 010 16.407a11.616 11.616 0 006.29 1.84" />
                      </svg>
                    </a>
                  </div>

                  <div>
                    <a
                      href="#"
                      className="w-full inline-flex justify-center py-2 px-4 border border-gray-300 rounded-md shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
                    >
                      <span className="sr-only">Sign in with GitHub</span>
                      <svg
                        className="w-5 h-5"
                        aria-hidden="true"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 0C4.477 0 0 4.484 0 10.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0110 4.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.203 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.942.359.31.678.921.678 1.856 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0020 10.017C20 4.484 15.522 0 10 0z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </a>
                  </div>
                </div>
              </div>

              <div className="mt-6 relative">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center text-sm">
                  <span className="px-2 bg-white text-gray-500">
                    Or continue with
                  </span>
                </div>
              </div>
            </div>
            */}

						<div className='mt-6'>
							<form action='#' method='POST' className='space-y-6'>
								<div>
									<label
										htmlFor='email'
										className='block text-sm font-medium text-gray-700'
									>
										Email address
									</label>
									<div className='mt-1'>
										<input
											id='email'
											name='email'
											type='email'
											autoComplete='email'
											required
											className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
											value={state.email}
											onChange={handleChange}
										/>
									</div>
								</div>

								<div className='space-y-1'>
									<label
										htmlFor='password'
										className='block text-sm font-medium text-gray-700'
									>
										Password
									</label>
									<div className='mt-1'>
										<input
											id='password'
											name='password'
											type='password'
											autoComplete='current-password'
											required
											className='appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
											value={state.password}
											onChange={handleChange}
										/>
									</div>
								</div>

								<div className='flex items-center justify-between'>
									<div className='flex items-center'>
										<input
											id='remember-me'
											name='remember-me'
											type='checkbox'
											className='h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded'
											value={rememberMe}
											onChange={() => setRememberMe(!rememberMe)}
										/>
										<label
											htmlFor='remember-me'
											className='ml-2 block text-sm text-gray-900'
										>
											Remember me
										</label>
									</div>

									{/*
                  <div className="text-sm">
                    <a
                      href="#"
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Forgot your password?
                    </a>
                  </div>
                  */}
								</div>

								{errorMessage ? (
									<div
										style={{
											padding: 20,
											backgroundColor: '#ffcaca',
											borderLeft: '4px solid #ff4141',
											color: 'black',
											fontSize: 14,
											marginBottom: 20,
										}}
									>
										{errorMessage}
									</div>
								) : null}

								<div>
									<button
										type='button'
										className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
										onClick={attemptLogin}
									>
										Sign in
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<div className='hidden lg:block relative w-0 flex-1'>
				<img
					className='absolute inset-0 h-full w-full object-cover'
					src='https://images.unsplash.com/photo-1505904267569-f02eaeb45a4c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80'
					alt=''
				/>
			</div>
		</div>
	)
}

// function SMStest() {
// 	return (
// 		<React.Fragment>
// 			<div className='border-t-4 mt-5'>For testing:</div>
// 			<div ref={capchaContainer}></div>
// 			{confirmation ? (
// 				<div className='mt-5'>
// 					<div className='space-y-1'>
// 						<div className='pb-5'>
// 							<label
// 								htmlFor='phoneNumber'
// 								className='block text-sm font-medium text-gray-700'
// 							>
// 								Verification code from SMS
// 							</label>
// 							<input
// 								type='text'
// 								className='focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
// 								placeholder='Your code from SMS'
// 								value={verificationCode}
// 								onChange={(event) => {
// 									SetVerificationCode(event.target.value)
// 								}}
// 							/>
// 						</div>
// 						<button
// 							onClick={handleCodeSubmit}
// 							className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
// 						>
// 							Confirm code
// 						</button>
// 					</div>
// 				</div>
// 			) : (
// 				<div className='mt-5'>
// 					<div className='space-y-1'>
// 						<div className='pb-5'>
// 							<label
// 								htmlFor='phoneNumber'
// 								className='block text-sm font-medium text-gray-700'
// 							>
// 								Phone Number
// 							</label>
// 							{/* <div class="mt-1 relative rounded-md shadow-sm">
//                     <div class="absolute inset-y-0 left-0 flex items-center">
//                       <label for="country" class="sr-only">
//                         Country
//                       </label>
//                       <select
//                         id="country"
//                         name="country"
//                         class="focus:ring-indigo-500 focus:border-indigo-500 h-full py-0 pl-3 pr-7 border-transparent bg-transparent text-gray-500 sm:text-sm rounded-md"
//                       >
//                         <option>Norge +47</option>
//                         <option>US +1</option>
//                         <option>EU</option>
//                       </select>
//                     </div>
//                     <input
//                       type="text"
//                       name="phone-number"
//                       id="phone-number"
//                       class="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-32 sm:text-sm border-gray-300 rounded-md"
//                       placeholder="+1 (555) 987-6543"
//                     />
//                   </div> */}
// 							<div>
// 								<input
// 									type='text'
// 									name='phoneNumber'
// 									id='phoneNumber'
// 									className='focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
// 									placeholder='999 22 999'
// 									value={state.phoneNumber}
// 									onChange={handleChange}
// 								/>
// 							</div>
// 						</div>
// 					</div>
// 					<button
// 						id='sendCode'
// 						// onClick={signInSubmit}
// 						onClick={() => {
// 							if (applicationVerifier.current) {
// 								applicationVerifier.current.verify()
// 							}
// 						}}
// 						ref={capchaButton}
// 						className='w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
// 					>
// 						Send Code
// 					</button>
// 				</div>
// 			)}
// 		</React.Fragment>
// 	)
// }
