// This is the root component in
// the React application.

import * as React from 'react'

import { BrowserRouter as Router } from 'react-router-dom'

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'

import { getAuth } from 'firebase/auth'

import firebaseApp from '../../utils/firebase.js'

const batchLink = new BatchHttpLink({ uri: '/api/graphql' })

const authLink = setContext(async (_, { headers }) => {
	// get the authentication token from local storage if it exists
	// const token = localStorage.getItem('token')
	// return the headers to the context so httpLink can read them

	return {
		headers: {
			...headers,
			authorization: token ? `Bearer ${token}` : '',
		},
	}
})

const client = new ApolloClient({
	// uri: "/api/graphql",
	link: authLink.concat(batchLink),
	cache: new InMemoryCache(),
})

let token = ''

getAuth(firebaseApp).onAuthStateChanged(function (user) {
	if (user) {
		user.getIdToken().then(function (idToken) {
			token = idToken
		})
	} else {
		// localStorage.removeItem('token')
		token = ''
		client.clearStore()
	}
})

function Providers({ children }) {
	return (
		<ApolloProvider client={client}>
			<Router>{children}</Router>
		</ApolloProvider>
	)
}

export default Providers
