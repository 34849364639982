import * as React from 'react'

import { Link, useParams, useNavigate } from 'react-router-dom'
import { useQuery, useMutation, NetworkStatus } from '@apollo/client'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'

import {
	CheckCircleIcon,
	ChevronLeftIcon,
	ChevronRightIcon,
	ExclamationIcon,
	PlusIcon,
} from '@heroicons/react/solid'

import { XIcon } from '@heroicons/react/outline'

import EpisodeItem from '../EpisodeItem'
import ContentSetItem from '../ContentSetItem'

import InputImageUpload from '../InputImageUpload'
import InputText from '../InputText'
import InputTextArea from '../InputTextArea'
import InputDateTime from '../InputDateTime'
import InputToggle from '../InputToggle'

import SlideOver from '../SlideOver'

import { loader } from 'graphql.macro'
import { cloneDeep } from '@apollo/client/utilities'
const VIEWER_CONTENTSETS = loader('./ViewerContentSets.graphql')
const VIEWER_EPISODES = loader('./ViewerEpisodes.graphql')
const CONTENTSET = loader('./ContentSet.graphql')
const CREATE_CONTENTSET = loader('./CreateContentSet.graphql')
const UPDATE_CONTENTSET = loader('./UpdateContentSet.graphql')

export default function ContentSets() {
	/*<EditContentSetSlideOver /> */
	let { groupId } = useParams()
	let navigate = useNavigate()
	// const [editEpisodeDialog, setEditEpisodeDialog] = React.useState(true);

	function closeEditGroupDialog() {
		// do some navigation
		navigate('../content-sets')
	}

	const showEditGroupDialog = groupId ? true : false

	return (
		<div className='px-4 py-6 sm:px-6 lg:px-8 overflow-y-auto'>
			{' '}
			{/* h-full */}
			<div className='mb-6'>
				<Link
					to='new'
					type='button'
					className='inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
				>
					Lag et nytt innholdssett
				</Link>
			</div>
			{/* <button
        type="button"
        className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Edit
      </button>
      <button
        type="button"
        className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
      >
        Publish
      </button> */}
			<div className='overflow-y-auto'>
				<ContentSetList />
			</div>
			<PaginationPlaceholder />
			{groupId ? (
				<EditContentSetSlideOver
					key={groupId}
					groupId={groupId}
					open={showEditGroupDialog}
					setOpen={closeEditGroupDialog}
				/>
			) : null}
		</div>
	)
}

function ContentSetList() {
	const { loading, error, data, networkStatus } = useQuery(VIEWER_CONTENTSETS, {
		fetchPolicy: 'cache-first',
		variables: {
			contentSets: 50,
			contentItems: 50,
		},
		notifyOnNetworkStatusChange: true,
	})

	console.log('network status', networkStatus)

	if (networkStatus === NetworkStatus.refetch) {
		return 'Oppdaterer dataen...'
	}

	if (error) {
		return 'Error =/'
	}

	if (loading) {
		return 'Loading...'
	}

	const contentSets = data.viewer.organization.contentSets

	// console.log('contentSets', contentSets)

	return (
		<div className=''>
			{/* <div>count: {contentSets.totalCount}</div> */}
			<ul>
				{contentSets?.nodes?.map((contentSet) => {
					/*
          <li>
							title: {contentSet.title} <br />
							description: {contentSet.description} <br />
							<div style={{ marginLeft: 20 }}>
								episoder {contentSet.contentItems.episodeCount} <br />
								innholdsset {contentSet.contentItems.contentSetCount} <br />
							</div>
						</li>
          */

					return (
						<li key={contentSet.id} className='mb-2'>
							<Link to={`${contentSet.id}`}>
								<ContentSetItem contentSet={contentSet} />
							</Link>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

function EditContentSetSlideOver({
	// secondDialog,
	// setSecondDialog,
	// open,
	setOpen,
	groupId,
}) {
	const [openChooseEpisodes, setOpenChooseEpisodes] = React.useState(false)
	const [editedDataInForm, setEditedDataInForm] = React.useState({})

	const { loading, error, data } = useQuery(CONTENTSET, {
		variables: {
			contentItems: 50,
			contentSetId: groupId,
		},
		skip: groupId === 'new',
	})

	const [
		createContentSet,
		{
			// data: createContentSetData,
			loading: createContentSetLoading,
			// error: createContentSetError,
		},
	] = useMutation(CREATE_CONTENTSET, {
		// refetchQueries: [VIEWER_EPISODE, VIEWER_EPISODES],
		refetchQueries: ['ContentSets'],
	})

	const [
		updateContentSet,
		{
			// data: updateContentSetData,
			loading: updateContentSetLoading,
			// error: updateContentSetError,
		},
	] = useMutation(UPDATE_CONTENTSET, {
		// refetchQueries: [VIEWER_EPISODE, VIEWER_EPISODES],
		refetchQueries: ['ContentSets'],
	})

	const [graphqlDataSnapshot, setGraphqlDataSnapshot] = React.useState(null)

	React.useEffect(() => {
		if (loading) {
			return
		}

		if (error) {
			return
		}

		if (data && graphqlDataSnapshot === null) {
			setGraphqlDataSnapshot(data)
		} else if (groupId === 'new' && graphqlDataSnapshot === null) {
			setGraphqlDataSnapshot({
				contentSet: {},
			})
		}
	}, [loading, error, data, graphqlDataSnapshot, groupId])

	const mergedData = {
		...graphqlDataSnapshot?.contentSet,
		...editedDataInForm,
	}

	// console.log('------- datasets')
	// console.log('dataFromGraphQL:', graphqlDataSnapshot?.contentSet)
	// console.log('editedDataInForm:', editedDataInForm)
	// console.log('mergedData', mergedData)

	const onDragEnd = (result) => {
		const {
			destination,
			source,
			//draggableId
		} = result

		if (!destination) {
			return
		}

		if (
			destination.droppableId === source.droppableId &&
			destination.index === source.index
		) {
			return
		}

		let clonedEditedData = cloneDeep(
			// editedDataInForm ? editedDataInForm : graphqlDataSnapshot
			mergedData
		)
		const splicedEdge = clonedEditedData.contentItems.edges.splice(
			source.index,
			1
		)[0]
		clonedEditedData.contentItems.edges.splice(
			destination.index,
			0,
			splicedEdge
		)
		clonedEditedData.contentItems.edges =
			clonedEditedData.contentItems.edges.map((edge, index) => {
				return {
					...edge,
					order: index + 1,
				}
			})

		// setGraphqlDataSnapshot(clonedEditedData)
		setEditedDataInForm({
			...editedDataInForm,
			contentItems: clonedEditedData.contentItems,
		})
	}

	function closeContentSet() {
		if (
			window.confirm(
				'Er du sikker på at du vil avbryte og forkaste endringene dine?'
			)
		) {
			setOpen(false)
		}
	}

	async function saveContentSet(event) {
		const clonedEditedDataInForm = {
			...editedDataInForm,
		}

		if (Array.isArray(editedDataInForm?.contentItems?.edges)) {
			const transformedContentItems = editedDataInForm?.contentItems?.edges.map(
				(contentItemEdge) => {
					return {
						type: contentItemEdge.node.__typename,
						id: contentItemEdge.node.id,
						order: contentItemEdge.order,
					}
				}
			)

			clonedEditedDataInForm.contentItems = transformedContentItems
		} else {
			delete clonedEditedDataInForm?.contentItems
		}

		if (groupId === 'new') {
			// new contentSet -> create it

			event.preventDefault()
			await createContentSet({ variables: { input: clonedEditedDataInForm } })
			setOpen(false)
		} else if (mergedData.id) {
			// excisting contentSet -> update it
			event.preventDefault()
			await updateContentSet({
				variables: { id: mergedData.id, input: clonedEditedDataInForm },
			})
			setOpen(false)
		}
	}

	function contentItemsAdded({ addedContentItems }) {
		let clonedContentItems = mergedData?.contentItems
			? cloneDeep(mergedData.contentItems)
			: { edges: [] }

		// const splicedEdge = clonedEditedData.contentItems.edges.splice(
		// 	source.index,
		// 	1
		// )[0]
		// clonedEditedData.contentItems.edges.splice(
		// 	destination.index,
		// 	0,
		// 	splicedEdge
		// )
		if (!Array.isArray(addedContentItems) || addedContentItems?.length === 0) {
			// early return if not array or 0 items in array
			return
		}

		clonedContentItems.edges = [
			...clonedContentItems?.edges,
			...addedContentItems,
		]
		clonedContentItems.edges = clonedContentItems.edges.map((edge, index) => {
			return {
				...edge,
				order: index + 1,
			}
		})

		setEditedDataInForm({
			...editedDataInForm,
			contentItems: clonedContentItems,
		})
	}

	function removeEpisode(episodeId) {
		const clonedContentItems = cloneDeep(mergedData.contentItems)

		const indexToRemove = mergedData?.contentItems?.edges.findIndex(
			(episodeEdge) => episodeEdge.node.id === episodeId
		)
		clonedContentItems.edges.splice(indexToRemove, 1)
		setEditedDataInForm({
			...editedDataInForm,
			contentItems: clonedContentItems,
		})
	}

	if (loading) {
		return null
	}

	if (groupId === 'new' || mergedData.id) {
		/*
    title
    description
    approved
    publishDateTime
    unpublishDateTime
    coverImage
    contentItems
      type ( Episode | ContentSet )
      id
      order
    */

		let contentItemsInContentSet = []
		if (mergedData?.contentItems?.edges?.length > 0) {
			contentItemsInContentSet = mergedData?.contentItems?.edges.map(
				(contentItem) => {
					return contentItem?.node?.id
				}
			)
		}

		// do logic to compare dataFromGraphQL with editedDataInForm,
		// and remove from editedDataInForm if the value is equal to what we have from GraphQL

		return (
			<SlideOver open={true} setOpen={setOpen}>
				<SlideOver.Title handleClose={closeContentSet}>
					{groupId === 'new'
						? `Nytt innholdssett ${
								mergedData.title ? `"${mergedData.title}"` : ''
						  }`
						: `Redigerer innholdssettet "${mergedData.title}"`}
				</SlideOver.Title>
				<SlideOver.Content>
					<div className='grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6 mb-48'>
						<div className='sm:col-span-6'>
							<InputToggle
								id='approved'
								label='Publiserings Status'
								helperText='Et innholdssett må være godkjent før det kan gå live.'
								toggleLabel={mergedData.approved ? 'Godkjent' : 'Ikke godkjent'}
								toggleHelperText={
									mergedData.approved
										? '(publiseres på publiseringsdatoen)'
										: '(vil ikke bli publisert)'
								}
								value={mergedData.approved}
								onChange={(inputApproved) => {
									setEditedDataInForm({
										...editedDataInForm,
										approved: inputApproved,
									})
								}}
							/>
						</div>
						<div className='sm:col-span-6'>
							<InputImageUpload
								helperText='Bilde er påkrevd for å publisere et innholdssett'
								imageUrl={mergedData.coverImage}
								// need to fix this interface
								episodeData={editedDataInForm}
								setEpisodeData={setEditedDataInForm}
							/>
						</div>
						<div className='sm:col-span-6'>
							<InputText
								id='title'
								label='Tittel'
								helperText='Tittel er påkrevd for å publisere et innholdssett'
								required
								value={mergedData?.title ? mergedData.title : ''}
								onChange={(event) => {
									setEditedDataInForm({
										...editedDataInForm,
										title: event.target.value,
									})
								}}
							/>
						</div>
						<div className='sm:col-span-6'>
							<InputTextArea
								id='description'
								label='Beskrivelse'
								required
								helperText='Beskrivelsen er påkrevd for å publisere et innholdssett'
								value={mergedData?.description ? mergedData.description : ''}
								onChange={(event) => {
									setEditedDataInForm({
										...editedDataInForm,
										description: event.target.value,
									})
								}}
							/>
						</div>
						<div className='sm:col-span-6'>
							<InputDateTime
								id='publishDateTime'
								label='Publiseringstidspunkt'
								value={mergedData?.publishDateTime}
								helperText='Publiseringstidspunkt er påkrevd for å publisere en episode.'
								required
								onChange={(dateTime) => {
									setEditedDataInForm({
										...editedDataInForm,
										publishDateTime: dateTime,
									})
								}}
							/>
						</div>
						<div className='sm:col-span-6'>
							<InputDateTime
								id='unpublishDateTime'
								label='Avpubliseringstidspunkt'
								value={mergedData?.unpublishDateTime}
								helperText='Dato hvor episoden automatisk skal fjernes fra Talker.'
								onChange={(dateTime) => {
									setEditedDataInForm({
										...editedDataInForm,
										unpublishDateTime: dateTime,
									})
								}}
							/>
						</div>
						<div className='sm:col-span-6'>
							<span className='block text-sm font-medium text-gray-700 mb-4'>
								Innholds elementer
							</span>
							{mergedData?.contentItems?.edges?.length <= 0 ? (
								<div className='text-center border-2 border-gray-300 border-dashed rounded-lg my-4 py-4'>
									<h3 className='mt-2 text-sm font-medium text-gray-900'>
										Ingen innholdselementer
									</h3>
									<p className='mt-1 text-sm text-gray-500'>
										Legg til en eller flere innholdselementer for å komme i gang
										med innholdselementer.
									</p>
								</div>
							) : null}
							<DragDropContext onDragEnd={onDragEnd}>
								<Droppable
									droppableId={mergedData.id ? mergedData.id : 'new-contentSet'}
								>
									{(provided) => (
										<ul ref={provided.innerRef} {...provided.droppableProps}>
											{mergedData?.contentItems?.edges?.map(
												(contentItem, contentItemIndex) => {
													if (contentItem.node.__typename === 'Episode') {
														return (
															<Draggable
																key={contentItem.node.id}
																draggableId={contentItem.node.id}
																index={contentItemIndex}
															>
																{(provided) => (
																	<li
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		className='mb-2'
																	>
																		<EpisodeItem episode={contentItem.node}>
																			<EpisodeItem.Actions>
																				<button
																					onClick={() => {
																						removeEpisode(contentItem.node.id)
																					}}
																					type='button'
																					className='inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
																				>
																					Fjern episoden
																				</button>
																				{/* <button
																					type='button'
																					className='ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
																				>
																					Publish
																				</button> */}
																			</EpisodeItem.Actions>
																		</EpisodeItem>
																	</li>
																)}
															</Draggable>
														)
													} else if (
														contentItem.node.__typename === 'ContentSet'
													) {
														return (
															<Draggable
																key={contentItem.node.id}
																draggableId={contentItem.node.id}
																index={contentItemIndex}
															>
																{(provided) => (
																	<li
																		ref={provided.innerRef}
																		{...provided.draggableProps}
																		{...provided.dragHandleProps}
																		className='mb-2'
																	>
																		<ContentSetItem
																			contentSet={contentItem.node}
																		/>
																	</li>
																)}
															</Draggable>
														)
													} else {
														return null
													}
												}
											)}
											{provided.placeholder}
										</ul>
									)}
								</Droppable>
							</DragDropContext>
							<div className='mt-4'>
								<button
									type='button'
									className='inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
									onClick={() => setOpenChooseEpisodes(true)}
								>
									<PlusIcon className='-ml-1 mr-2 h-5 w-5' aria-hidden='true' />
									Legg til Episoder
								</button>
							</div>
						</div>
					</div>
				</SlideOver.Content>
				<SlideOver.Actions>
					<div className='flex justify-start'>
						<button
							type='submit'
							className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
							onClick={saveContentSet}
						>
							{createContentSetLoading || updateContentSetLoading
								? 'Lagrer...'
								: 'Lagre'}
						</button>
						<button
							type='button'
							className='ml-4 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
							onClick={closeContentSet}
						>
							Avbryt
						</button>
					</div>
				</SlideOver.Actions>
				<SlideOver open={openChooseEpisodes} size='max-w-4xl'>
					<ChooseEpisodesDialog
						onContentItemsAdded={contentItemsAdded}
						setOpen={setOpenChooseEpisodes}
						contentItemsInContentSet={contentItemsInContentSet}
					/>
				</SlideOver>
			</SlideOver>
		)
	} else {
		return (
			<SlideOver open={true} setOpen={setOpen}>
				<button
					type='button'
					className='bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
					onClick={() => setOpen(false)}
				>
					<span className='sr-only'>Close panel</span>
					<XIcon className='h-6 w-6' aria-hidden='true' />
				</button>
				404
			</SlideOver>
		)
	}
}

function ChooseEpisodesDialog({
	setOpen,
	contentItemsInContentSet,
	onContentItemsAdded,
}) {
	const [selected, setSelected] = React.useState([])
	const { loading, error, data } = useQuery(VIEWER_EPISODES, {
		variables: {
			// contentSets: 50,
			// contentItems: 50,
		},
	})

	if (error) {
		return 'Error =/'
	}

	if (loading) {
		return 'Loading...'
	}

	// console.log('data', data)

	const episodes = data?.viewer?.organization?.episodes?.nodes

	function addEpisodes() {
		if (!Array.isArray(selected) || selected.length === 0) {
			return
		}

		const addedEdges = selected.map((episodeId) => {
			return {
				__typename: 'ContentItemEdge',
				order: 0,
				node: episodes.find((episode) => episode.id === episodeId),
			}
		})

		if (typeof onContentItemsAdded === 'function') {
			onContentItemsAdded({
				addedContentItems: addedEdges,
			})
			setOpen(false)
		}
	}

	return (
		<React.Fragment>
			<SlideOver.Title handleClose={setOpen}>
				Velg episoder å legge til
			</SlideOver.Title>
			<SlideOver.Content>
				<div>
					{episodes?.map((episode, episodeIndex) => {
						if (
							contentItemsInContentSet.find(
								(excistingContentItemId) =>
									excistingContentItemId === episode.id
							)
						) {
							return (
								<div key={episode.id} className='mb-2 relative'>
									<div className='absolute inset-0 cursor-not-allowed outline-none rounded bg-yellow-50/75  flex items-center justify-center'>
										<div className='text-yellow-700 py-2 px-4 bg-yellow-50 rounded border border-yellow-500 flex items-center justify-center'>
											<ExclamationIcon
												className='h-5 w-5 mr-2 text-yellow-400'
												aria-hidden='true'
											/>{' '}
											Episoden er allerede lagt til i innholdssettet
										</div>
									</div>
									<EpisodeItem key={episode?.id} episode={episode} />
								</div>
							)
						}

						const episodeSelectedIndex = selected.findIndex(
							(selectedId) => selectedId === episode.id
						)

						return (
							<div className='mb-2 relative'>
								<div
									className={
										episodeSelectedIndex > -1
											? 'absolute inset-0 cursor-pointer outline-none ring-2 ring-offset-0 ring-indigo-500 rounded bg-indigo-50/50  flex items-center justify-center'
											: 'absolute inset-0 cursor-pointer'
									}
									onClick={() => {
										// const episodeIndex = selected.findIndex(
										// 	(selectedId) => selectedId === episode.id
										// )
										if (episodeSelectedIndex > -1) {
											const clonedSelected = [...selected]
											clonedSelected.splice(episodeSelectedIndex, 1)
											setSelected(clonedSelected)
										} else {
											const clonedSelected = [...selected]
											clonedSelected.push(episode.id)
											setSelected(clonedSelected)
										}
									}}
								>
									{episodeSelectedIndex > -1 ? (
										<div className='text-indigo-800 select-none py-2 px-4 bg-blue-50 rounded border-2 border-indigo-500 flex items-center justify-center'>
											<CheckCircleIcon
												className='h-5 w-5 mr-2 text-indigo-400'
												aria-hidden='true'
											/>{' '}
											Episoden er valgt
										</div>
									) : null}
								</div>
								<EpisodeItem key={episode?.id} episode={episode} />
							</div>
						)
					})}
				</div>
			</SlideOver.Content>
			<SlideOver.Actions>
				<div className='flex justify-start'>
					<button
						type='button'
						onClick={addEpisodes}
						className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
					>
						Legg til valgte episoder
					</button>
					<button
						type='button'
						className='ml-4 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
						onClick={() => setOpen(false)}
					>
						Avbryt
					</button>
				</div>
			</SlideOver.Actions>
		</React.Fragment>
	)
}

/*
function EpisodeList({ contentItemsInContentSet }) {
	const [selected, setSelected] = React.useState([])
	const { loading, error, data } = useQuery(VIEWER_EPISODES, {
		variables: {
			// contentSets: 50,
			// contentItems: 50,
		},
	})

	if (error) {
		return 'Error =/'
	}

	if (loading) {
		return 'Loading...'
	}

	console.log('data', data)

	const episodes = data?.viewer?.organization?.episodes?.nodes

	return (
		<div>
			{episodes?.map((episode, episodeIndex) => {
				if (
					contentItemsInContentSet.find(
						(excistingContentItemId) => excistingContentItemId === episode.id
					)
				) {
					return (
						<div className='mb-2 relative'>
							<div className='absolute inset-0 cursor-not-allowed outline-none rounded bg-yellow-50/75  flex items-center justify-center'>
								<div className='text-yellow-700 py-2 px-4 bg-yellow-50 rounded border border-yellow-500 flex items-center justify-center'>
									<ExclamationIcon
										className='h-5 w-5 mr-2 text-yellow-400'
										aria-hidden='true'
									/>{' '}
									Episoden er allerede lagt til i innholdssettet
								</div>
							</div>
							<EpisodeItem key={episode?.id} episode={episode} />
						</div>
					)
				}

				const episodeSelectedIndex = selected.findIndex(
					(selectedId) => selectedId === episode.id
				)

				return (
					<div className='mb-2 relative'>
						<div
							className={
								episodeSelectedIndex > -1
									? 'absolute inset-0 cursor-pointer outline-none ring-2 ring-offset-0 ring-indigo-500 rounded bg-indigo-50/50  flex items-center justify-center'
									: 'absolute inset-0 cursor-pointer'
							}
							onClick={() => {
								// const episodeIndex = selected.findIndex(
								// 	(selectedId) => selectedId === episode.id
								// )
								if (episodeSelectedIndex > -1) {
									const clonedSelected = [...selected]
									clonedSelected.splice(episodeSelectedIndex, 1)
									setSelected(clonedSelected)
								} else {
									const clonedSelected = [...selected]
									clonedSelected.push(episode.id)
									setSelected(clonedSelected)
								}
							}}
						>
							{episodeSelectedIndex > -1 ? (
								<div className='text-indigo-800 select-none py-2 px-4 bg-blue-50 rounded border-2 border-indigo-500 flex items-center justify-center'>
									<CheckCircleIcon
										className='h-5 w-5 mr-2 text-indigo-400'
										aria-hidden='true'
									/>{' '}
									Episoden er valgt
								</div>
							) : null}
						</div>
						<EpisodeItem key={episode?.id} episode={episode} />
					</div>
				)
			})}
		</div>
	)
}
*/

function PaginationPlaceholder() {
	return (
		<div className='bg-white px-4 py-3 my-6 flex items-center justify-between border-t border-gray-200 sm:px-6'>
			<div className='flex-1 flex justify-between sm:hidden'>
				<a
					href='/temp'
					className='relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
				>
					Previous
				</a>
				<a
					href='/temp'
					className='ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50'
				>
					Next
				</a>
			</div>
			<div className='hidden sm:flex-1 sm:flex sm:items-center sm:justify-between'>
				<div>
					<p className='text-sm text-gray-700'>
						Viser <span className='font-medium'>1</span> til{' '}
						<span className='font-medium'>10</span> av{' '}
						<span className='font-medium'>97</span> innholdssett
					</p>
				</div>
				<div>
					<nav
						className='relative z-0 inline-flex rounded-md shadow-sm -space-x-px'
						aria-label='Pagination'
					>
						<a
							href='/temp'
							className='relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
						>
							<span className='sr-only'>Neste</span>
							<ChevronLeftIcon className='h-5 w-5' aria-hidden='true' />
						</a>
						{/* Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-50" */}
						<a
							href='/temp'
							aria-current='page'
							className='z-10 bg-indigo-50 border-indigo-500 text-indigo-600 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
						>
							1
						</a>
						<a
							href='/temp'
							className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
						>
							2
						</a>
						<a
							href='/temp'
							className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium'
						>
							3
						</a>
						<span className='relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700'>
							...
						</span>
						<a
							href='/temp'
							className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 hidden md:inline-flex relative items-center px-4 py-2 border text-sm font-medium'
						>
							8
						</a>
						<a
							href='/temp'
							className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
						>
							9
						</a>
						<a
							href='/temp'
							className='bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium'
						>
							10
						</a>
						<a
							href='/temp'
							className='relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50'
						>
							<span className='sr-only'>Next</span>
							<ChevronRightIcon className='h-5 w-5' aria-hidden='true' />
						</a>
					</nav>
				</div>
			</div>
		</div>
	)
}
