import * as React from 'react'

import { Routes, Route, Link, useLocation } from 'react-router-dom'

import ContentEpisodes from '../ContentEpisodes'
import ContentContentSets from '../ContentContentSets'

function Content() {
	const location = useLocation()
	const pathSegment = location?.pathname?.split('/')?.[4] || ''

	let tabs = [
		{ name: 'Dashbord', href: '', current: false },
		{ name: 'Hjem skjermen', href: 'home-screen', current: false },
		{ name: 'Innholds sett', href: 'content-sets', current: false },
		{ name: 'Episoder', href: 'episodes', current: false },
	]

	tabs = tabs.map((tab) => {
		return {
			...tab,
			current: tab.href === pathSegment,
		}
	})

	return (
		<div className='flex-1 flex xl:overflow-hidden max-h-screen'>
			{/* Primary column */}
			<section
				aria-labelledby='primary-heading'
				className='min-w-0 flex-1 flex flex-col overflow-hidden lg:order-last'
			>
				<div className='px-4 pt-12 sm:px-6 lg:px-8 bg-gray-100'>
					<div className='border-b border-gray-300'>
						<h1
							id='primary-heading'
							className='text-lg leading-6 font-medium text-gray-900'
						>
							Innhold
						</h1>
						<p className='text-gray-900 text-opacity-60'>
							Her kan du jobbe med alt innholdet som publiseres i appen.
						</p>
						<Tabs tabs={tabs} />
					</div>
				</div>
				<Routes>
					<Route index element={<div>Dashbord</div>} />
					<Route path='home-screen' element={<div>Hjem skjermen</div>} />
					<Route path='content-sets' element={<ContentContentSets />}>
						<Route index element={<ContentContentSets />} />
						<Route path=':groupId' element={<ContentContentSets />} />
					</Route>
					<Route path='episodes'>
						<Route index element={<ContentEpisodes />} />
						<Route path=':episodeId' element={<ContentEpisodes />} />
					</Route>
				</Routes>
			</section>

			{/* Secondary column (hidden on smaller screens) */}
			{/* <aside className="hidden lg:block lg:flex-shrink-0 lg:order-first">
              <div className="h-full relative flex flex-col w-96 border-r border-gray-200 bg-white">
                test2
              </div>
            </aside> */}
		</div>
	)
}

export default Content

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

function Tabs({ tabs }) {
	return (
		<div className='mt-3 sm:mt-4'>
			<div className='sm:hidden'>
				<label htmlFor='current-tab' className='sr-only'>
					Select a tab
				</label>
				<select
					id='current-tab'
					name='current-tab'
					className='block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
					defaultValue={tabs.find((tab) => tab.current)?.name}
				>
					{tabs.map((tab) => (
						<option key={tab.name}>{tab.name}</option>
					))}
				</select>
			</div>
			<div className='hidden sm:block'>
				<nav className='-mb-px flex space-x-8'>
					{tabs.map((tab) => (
						<Link
							key={tab.name}
							// href={tab.href}
							to={tab.href}
							className={classNames(
								tab.current
									? 'border-indigo-500 text-indigo-600'
									: 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
								'whitespace-nowrap pb-4 px-1 border-b-2 font-medium text-sm'
							)}
							aria-current={tab.current ? 'page' : undefined}
						>
							{tab.name}
						</Link>
					))}
				</nav>
			</div>
		</div>
	)
}
