import * as React from 'react'

import { Switch } from '@headlessui/react'

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function InputToggle({
	id,
	onChange,
	value,
	label,
	helperText,
	required,
	toggleLabel,
	toggleHelperText,
}) {
	return (
		<fieldset className=''>
			<div>
				<legend className='text-sm font-medium text-gray-700'>
					{label ? label : ''}
					{required ? ' *' : ''}
				</legend>
			</div>
			<div className='mt-4 space-y-4'>
				<Switch.Group as='div' className='flex items-center'>
					<Switch
						id={id}
						name={id}
						checked={value}
						onChange={onChange}
						className={classNames(
							value ? 'bg-indigo-600' : 'bg-gray-200',
							'relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
						)}
					>
						<span
							aria-hidden='true'
							className={classNames(
								value ? 'translate-x-5' : 'translate-x-0',
								'pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200'
							)}
						/>
					</Switch>
					<Switch.Label as='span' className='ml-3'>
						<span className='text-sm font-medium text-gray-900'>
							{toggleLabel}{' '}
						</span>
						<span className='text-sm text-gray-500'>{toggleHelperText}</span>
					</Switch.Label>
				</Switch.Group>
				<p className='mt-1 text-sm text-gray-500'>{helperText}</p>
				{/* <p className='mt-1 text-sm text-red-500'>
					Publiseringsstatus kan ikke settes til godkjent grunnet at episoden
					mangler informasjon.
				</p> */}
			</div>
		</fieldset>
	)
}
