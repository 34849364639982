import { initializeApp } from 'firebase/app'
import 'firebase/auth'
import 'firebase/database'

/*
const config = {
	// apiKey: '',
	// authDomain: '',
	// databaseURL: '',
	projectId: 'talker-media',
	// storageBucket: '',
	// messagingSenderId: '',
	apiKey: 'AIzaSyCfenxnAkOB9cns8pyVgKCi4CW3UDaD2sA', // client key
	authDomain: 'talker-media.firebaseapp.com',
}
*/

const config = {
	apiKey: 'AIzaSyDC9D3RGx0xdZX8NJrpUXpXchBEMZEC548',
	authDomain: 'talker-prod-central-resources.firebaseapp.com',
	projectId: 'talker-prod-central-resources',
	storageBucket: 'talker-prod-central-resources.appspot.com',
	messagingSenderId: '597655804425',
	appId: '1:597655804425:web:f102e9b7bbf2ba96c736d8',
	// apiKey: 'AIzaSyCfenxnAkOB9cns8pyVgKCi4CW3UDaD2sA',
	// authDomain: 'talker-media.firebaseapp.com',
	// projectId: 'talker-media',
	// storageBucket: 'talker-media.appspot.com',
	// messagingSenderId: '588589973626',
	// appId: '1:588589973626:web:ea7bc19e9f43ec2fd7bbc3',
	// measurementId: 'G-D51PHSHCDS',
	// databaseURL:
	// 	'https://talker-media-default-rtdb.europe-west1.firebasedatabase.app',
}

const app = initializeApp(config)

export default app
