import React from 'react'
// import ReactDOM from "react-dom";
import { createRoot } from 'react-dom/client'
import './index.css'
import Root from './components/Root'
import reportWebVitals from './reportWebVitals'

const container = document.getElementById('root')
const reactRoot = createRoot(container)

// disabled StrictMode for now since it does not work with Headless UI
reactRoot.render(
	// <React.StrictMode>
	<Root />
	// </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
