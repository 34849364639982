// This is the root component in
// the React application.
// import Header from '../Header';

import React from 'react'

import Providers from './Providers'

import App from '../App'

function Root() {
	return (
		<Providers>
			<App />
		</Providers>
	)
}

export default Root
