import * as React from 'react'
import { getAuth } from 'firebase/auth'
import { useParams } from 'react-router-dom'

export default function InputImageUpload({
	imageUrl,
	episodeData,
	setEpisodeData,
	helperText,
}) {
	const { organizationId } = useParams()

	const uploadPhoto = async (event) => {
		const userToken = await getAuth().currentUser.getIdToken()
		// const decodedUserToken = awaitgetAuth().currentUser.getIdTokenResult()
		// console.log('decodedUserToken', decodedUserToken)

		const file = event.target.files[0]

		if (file && userToken) {
			const API_BASEURL = process.env.REACT_APP_API_BASEURL?.replace(
				':organizationId',
				organizationId
			)

			// const res = await fetch(`${API_BASEURL}/upload/episode/coverImage`, {
			const res = await fetch(`${API_BASEURL}/getUploadToken`, {
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			})
			const { url, fields, publicFileUrl } = await res.json()
			const formData = new FormData()

			Object.entries({ ...fields, file }).forEach(([key, value]) => {
				formData.append(key, value)
			})

			const upload = await fetch(url, {
				method: 'POST',
				body: formData,
			})

			if (upload.ok) {
				// console.log('Uploaded successfully!')
				// debugger
				setEpisodeData({
					...episodeData,
					coverImage: publicFileUrl,
				})
			} else {
				// console.error('Upload failed.')
			}
		}
	}

	if (imageUrl) {
		return (
			<React.Fragment>
				<label htmlFor='' className='block text-sm font-medium text-gray-700'>
					Bilde *
				</label>
				<div className=''>
					<img
						className='max-h-72 rounded-md overflow-hidden'
						src={imageUrl}
						alt=''
					/>
				</div>
				<div className='my-2'>
					<button
						className='bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
						onClick={() => {
							window.open(imageUrl)
						}}
					>
						Vis større
					</button>

					<label
						htmlFor='file-upload'
						className='ml-4 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
					>
						<span className=''>Last opp nytt bilde</span>
						<input
							id='file-upload'
							name='file-upload'
							className='sr-only'
							type='file'
							accept='image/png, image/jpeg'
							onChange={uploadPhoto}
						/>
					</label>
					<button
						className='ml-4 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
						onClick={() => {
							setEpisodeData({
								...episodeData,
								coverImage: '',
							})
						}}
					>
						Fjern bildet
					</button>
				</div>
			</React.Fragment>
		)
	}
	return (
		<React.Fragment>
			<label
				htmlFor='cover-photo'
				className='block text-sm font-medium text-gray-700'
			>
				Bilde *
			</label>
			<div className='mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
				<div className='space-y-1 text-center'>
					<svg
						className='mx-auto h-12 w-12 text-gray-400'
						stroke='currentColor'
						fill='none'
						viewBox='0 0 48 48'
						aria-hidden='true'
					>
						<path
							d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
							strokeWidth={2}
							strokeLinecap='round'
							strokeLinejoin='round'
						/>
					</svg>
					<div className='flex text-sm text-gray-600'>
						<label
							htmlFor='file-upload'
							className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
						>
							<span>Last opp en fil</span>
							<input
								id='file-upload'
								name='file-upload'
								className='sr-only'
								type='file'
								accept='image/png, image/jpeg'
								onChange={uploadPhoto}
							/>
						</label>
						<p className='pl-1'>eller dra og slipp en fil her</p>
					</div>
					<p className='text-xs text-gray-500'>PNG, JPG, GIF opp til 10MB</p>
				</div>
			</div>
			<p className='mt-2 text-sm text-gray-500'>{helperText}</p>
		</React.Fragment>
	)
}
