import * as React from 'react'

import { Dialog, /* Menu, */ Transition } from '@headlessui/react'
import {
	HomeIcon,
	MenuAlt2Icon,
	UserGroupIcon,
	XIcon,
	ChartSquareBarIcon,
	CollectionIcon,
} from '@heroicons/react/outline'
// import { SearchIcon } from "@heroicons/react/solid";

import {
	Routes,
	Route,
	Link,
	useLocation,
	useParams,
	NavLink,
} from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'

import TalkerLogo from './TalkerLogo'

import Profile from '../Profile'
import Content from '../Content'
import Reports from '../Reports'
import Users from '../Users'

const VIEWER_PROFILE = gql`
	query {
		viewer {
			id
			firstname
			email
			avatarUrl
			organization {
				id
				name
				avatarUrl
			}
		}
	}
`

const sidebarNavigation = [
	{ name: 'Dashbord', href: '', icon: HomeIcon },
	{ name: 'Innhold', href: 'content', icon: CollectionIcon },
	{
		name: 'Rapporter',
		href: 'reports',
		icon: ChartSquareBarIcon,
	},
	{ name: 'Brukere', href: 'users', icon: UserGroupIcon },
]

// const userNavigation = [
//   { name: "Your Profile", href: "#" },
//   { name: "Sign out", href: "#" },
// ];

function classNames(...classes) {
	return classes.filter(Boolean).join(' ')
}

export default function BackOffice() {
	const [mobileMenuOpen, setMobileMenuOpen] = React.useState(false)

	return (
		<div className='h-screen bg-gray-50 flex overflow-hidden'>
			{/* Narrow sidebar */}
			<MainMenu
				mobileMenuOpen={mobileMenuOpen}
				setMobileMenuOpen={setMobileMenuOpen}
			/>
			{/* Content area */}
			<div className='flex-1 min-w-0 flex flex-col overflow-hidden'>
				{/* Mobile top navigation */}
				<div className='md:hidden'>
					<div className='bg-indigo-600 py-2 px-4 flex items-center justify-between sm:px-6 lg:px-8'>
						<div>
							<img
								className='h-8 w-auto'
								src='https://tailwindui.com/img/logos/workflow-mark.svg?color=white'
								alt='Workflow'
							/>
						</div>
						<div>
							<button
								type='button'
								className='-mr-3 h-12 w-12 inline-flex items-center justify-center bg-indigo-600 rounded-md text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white'
								onClick={() => setMobileMenuOpen(true)}
							>
								<span className='sr-only'>Open sidebar</span>
								<MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
							</button>
						</div>
					</div>
				</div>
				<main className='overflow-y-auto bg-white min-h-screen'>
					<Routes>
						<Route path='/' element={<div className='p-5'>Dashbord</div>} />
						<Route path='content/*' element={<Content />} />
						<Route path='/reports' element={<Reports />} />
						<Route path='/users' element={<Users />} />
						<Route path='/profile' element={<Profile />} />
						<Route path='*' element={<div className='p-5'>Not found</div>} />
					</Routes>
				</main>
			</div>
		</div>
	)
}

function MainMenu({ mobileMenuOpen, setMobileMenuOpen }) {
	const { loading, error, data } = useQuery(VIEWER_PROFILE)
	let location = useLocation()
	// let urlParams = useParams()

	if (loading) return <p>Loading...</p>
	if (error) {
		// console.log(error)
		return <p>Error :(</p>
	}

	return (
		<React.Fragment>
			<MobileMenu
				mobileMenuOpen={mobileMenuOpen}
				setMobileMenuOpen={setMobileMenuOpen}
				sidebarNavigation={sidebarNavigation}
			></MobileMenu>
			<div className='hidden w-34 bg-indigo-700 overflow-y-auto md:flex md:flex-shrink-0'>
				<div className='w-full flex flex-col items-center'>
					<div className='flex-shrink-0 flex items-center pt-5'>
						{/* <img
              className="h-8 w-auto"
              src="https://storage.googleapis.com/talker-test/demos/avatars_organization/talker_white.png"
              style={{
                imageRendering: "-webkit-optimize-contrast",
              }}
              alt="Workflow"
            /> */}
						<TalkerLogo className='h-8 w-auto' />
					</div>
					<div className='flex-1 flex flex-col mt-6 w-full px-2 space-y-1'>
						{sidebarNavigation.map((item) => (
							<NavLink
								end={item.href == '' ? true : false}
								key={item.name}
								to={item.href}
								className={({ isActive }) => {
									return classNames(
										isActive
											? 'bg-indigo-800 text-white'
											: 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
										'group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
									)
								}}
								// aria-current={isActive ? 'page' : undefined}
							>
								<item.icon
									className={classNames(
										item.current
											? 'text-white'
											: 'text-indigo-300 group-hover:text-white',
										'h-6 w-6'
									)}
									aria-hidden='true'
								/>
								<span className='mt-2'>{item.name}</span>
							</NavLink>
						))}
					</div>

					<div className='flex-shrink-0 flex flex-col py-5 px-2'>
						<Link
							to='profile'
							className={classNames(
								location.pathname === '/profile'
									? 'bg-indigo-800 text-white'
									: 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
								'flex-shrink-0 group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
							)}
						>
							<div className='flex flex-col items-center'>
								<div>
									<img
										className='inline-block h-10 w-10 rounded-full'
										src={data?.viewer?.avatarUrl}
										alt=''
									/>
								</div>
								<div className='text-center'>
									<p className='text-sm font-medium mt-2'>
										{data?.viewer?.firstname}
									</p>
									<p className='text-xs font-medium'>Vis profil</p>
								</div>
							</div>
						</Link>
						<Link
							to='/'
							className={classNames(
								location.pathname === '/profile'
									? 'bg-indigo-800 text-white'
									: 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
								'flex-shrink-0 group w-full p-3 rounded-md flex flex-col items-center text-xs font-medium'
							)}
						>
							<div className='flex flex-col items-center'>
								<div className=''>
									<img
										className='inline-block h-14 w-20 p-2 rounded-md bg-white object-contain'
										src={data?.viewer?.organization?.avatarUrl}
										alt=''
									/>
								</div>
								<div className='text-center'>
									<p className='text-sm font-medium mt-2'>
										{/* {data?.viewer?.firstname} */}
										{data?.viewer?.organization?.name}
									</p>
									<p className='text-xs font-medium'>Bytt organisasjon</p>
								</div>
							</div>
						</Link>
					</div>
				</div>
			</div>
		</React.Fragment>
	)
}

function MobileMenu({ mobileMenuOpen, setMobileMenuOpen, sidebarNavigation }) {
	// TODO: Fix that menu has user and change organization

	return (
		<Transition.Root show={mobileMenuOpen} as={React.Fragment}>
			<Dialog
				as='div'
				static
				className='md:hidden'
				open={mobileMenuOpen}
				onClose={setMobileMenuOpen}
			>
				<div className='fixed inset-0 z-40 flex'>
					<Transition.Child
						as={React.Fragment}
						enter='transition-opacity ease-linear duration-300'
						enterFrom='opacity-0'
						enterTo='opacity-100'
						leave='transition-opacity ease-linear duration-300'
						leaveFrom='opacity-100'
						leaveTo='opacity-0'
					>
						<Dialog.Overlay className='fixed inset-0 bg-gray-600 bg-opacity-75' />
					</Transition.Child>
					<Transition.Child
						as={React.Fragment}
						enter='transition ease-in-out duration-300 transform'
						enterFrom='-translate-x-full'
						enterTo='translate-x-0'
						leave='transition ease-in-out duration-300 transform'
						leaveFrom='translate-x-0'
						leaveTo='-translate-x-full'
					>
						<div className='relative max-w-xs w-full bg-indigo-700 pt-5 pb-4 flex-1 flex flex-col'>
							<Transition.Child
								as={React.Fragment}
								enter='ease-in-out duration-300'
								enterFrom='opacity-0'
								enterTo='opacity-100'
								leave='ease-in-out duration-300'
								leaveFrom='opacity-100'
								leaveTo='opacity-0'
							>
								<div className='absolute top-1 right-0 -mr-14 p-1'>
									<button
										type='button'
										className='h-12 w-12 rounded-full flex items-center justify-center focus:outline-none focus:ring-2 focus:ring-white'
										onClick={() => setMobileMenuOpen(false)}
									>
										<XIcon className='h-6 w-6 text-white' aria-hidden='true' />
										<span className='sr-only'>Close sidebar</span>
									</button>
								</div>
							</Transition.Child>
							<div className='flex-shrink-0 px-4 flex items-center'>
								<img
									className='h-8 w-auto'
									src='https://tailwindui.com/img/logos/workflow-mark.svg?color=white'
									alt='Workflow'
								/>
							</div>
							<div className='mt-5 flex-1 h-0 px-2 overflow-y-auto'>
								<nav className='h-full flex flex-col'>
									<div className='space-y-1'>
										{sidebarNavigation.map((item) => (
											<a
												key={item.name}
												href={item.href}
												className={classNames(
													item.current
														? 'bg-indigo-800 text-white'
														: 'text-indigo-100 hover:bg-indigo-800 hover:text-white',
													'group py-2 px-3 rounded-md flex items-center text-sm font-medium'
												)}
												aria-current={item.current ? 'page' : undefined}
											>
												<item.icon
													className={classNames(
														item.current
															? 'text-white'
															: 'text-indigo-300 group-hover:text-white',
														'mr-3 h-6 w-6'
													)}
													aria-hidden='true'
												/>
												<span>{item.name}</span>
											</a>
										))}
									</div>
								</nav>
							</div>
						</div>
					</Transition.Child>
					<div className='flex-shrink-0 w-14' aria-hidden='true'>
						{/* Dummy element to force sidebar to shrink to fit close icon */}
					</div>
				</div>
			</Dialog>
		</Transition.Root>
	)
}
