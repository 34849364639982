import * as React from 'react'
import { getAuth } from 'firebase/auth'
import { useParams } from 'react-router-dom'

export default function InputAudioUpload({
	audioUrl,
	episodeData,
	setEpisodeData,
}) {
	const { organizationId } = useParams()

	const uploadAudio = async (event) => {
		const userToken = await getAuth().currentUser.getIdToken()
		// const decodedUserToken = awaitgetAuth().currentUser.getIdTokenResult()
		// console.log('decodedUserToken', decodedUserToken)

		const file = event.target.files[0]

		if (file && userToken) {
			const API_BASEURL = process.env.REACT_APP_API_BASEURL?.replace(
				':organizationId',
				organizationId
			)

			// const res = await fetch(`${API_BASEURL}/upload/episode/coverImage`, {
			const res = await fetch(`${API_BASEURL}/getUploadToken`, {
				headers: {
					Authorization: `Bearer ${userToken}`,
				},
			})
			const { url, fields, publicFileUrl } = await res.json()
			const formData = new FormData()

			Object.entries({ ...fields, file }).forEach(([key, value]) => {
				formData.append(key, value)
			})

			const upload = await fetch(url, {
				method: 'POST',
				body: formData,
			})

			if (upload.ok) {
				// console.log('Uploaded successfully!')
				// debugger
				setEpisodeData({
					...episodeData,
					audioUrl: publicFileUrl,
				})
			} else {
				// console.error('Upload failed.')
			}
		}
	}

	if (audioUrl) {
		return (
			<React.Fragment>
				<span className='block text-sm font-medium text-gray-700'>
					Lydfil *
				</span>
				<div className='mt-2'>
					<audio className='w-full' controls src={audioUrl}>
						Nettleseren din støtter ikke
						<code>audio</code>-elementet.
					</audio>
				</div>
				<button
					className='mt-2 bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
					onClick={() => {
						setEpisodeData({
							...episodeData,
							audioUrl: '',
						})
					}}
				>
					Fjern audio filen
				</button>
			</React.Fragment>
		)
	} else {
		return (
			<React.Fragment>
				<span className='block text-sm font-medium text-gray-700'>
					Lydfil *
				</span>
				<div className='mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md'>
					<div className='space-y-1 text-center'>
						<svg
							xmlns='http://www.w3.org/2000/svg'
							fill='none'
							viewBox='0 0 24 24'
							strokeWidth={1.5}
							stroke='currentColor'
							className='mx-auto h-12 w-12 text-gray-400'
						>
							<path
								strokeLinecap='round'
								strokeLinejoin='round'
								d='M9 9l10.5-3m0 6.553v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 11-.99-3.467l2.31-.66a2.25 2.25 0 001.632-2.163zm0 0V2.25L9 5.25v10.303m0 0v3.75a2.25 2.25 0 01-1.632 2.163l-1.32.377a1.803 1.803 0 01-.99-3.467l2.31-.66A2.25 2.25 0 009 15.553z'
							/>
						</svg>
						<div className='flex text-sm text-gray-600'>
							<label
								htmlFor='file-audio-upload'
								className='relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500'
							>
								<span>Last opp en fil</span>
								<input
									id='file-audio-upload'
									name='file-audio-upload'
									className='sr-only'
									type='file'
									accept='audio/mpeg3, audio/mpeg, audio/mpeg4'
									onChange={uploadAudio}
								/>
							</label>
							<p className='pl-1'>eller dra og slipp en fil her</p>
						</div>
						<p className='text-xs text-gray-500'>MP3 opp til 50MB</p>
					</div>
				</div>
				<p className='mt-2 text-sm text-gray-500'>
					Lydfil er påkrevd for å publisere en episode.
				</p>
			</React.Fragment>
		)
	}
}
