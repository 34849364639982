import * as React from 'react'

import { Dialog, Transition } from '@headlessui/react'

import { XIcon } from '@heroicons/react/outline'

function SlideOver({ children, open, size = 'max-w-7xl' }) {
	let refDiv = React.useRef(null)

	return (
		<Transition.Root show={open} as={React.Fragment}>
			<Dialog
				as='div'
				initialFocus={refDiv}
				className='fixed inset-0 overflow-hidden'
				// onClose={setOpen}
				onClose={() => null}
			>
				<div ref={refDiv}></div>
				<div className='absolute inset-0 overflow-hidden'>
					{/* <Dialog.Overlay className="absolute inset-0" /> */}
					<Dialog.Overlay className='absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />

					<div className='fixed inset-y-0 right-0 pl-10 max-w-full flex sm:pl-16'>
						<Transition.Child
							as={React.Fragment}
							enter='transform transition ease-in-out duration-500 sm:duration-700'
							enterFrom='translate-x-full'
							enterTo='translate-x-0'
							leave='transform transition ease-in-out duration-500 sm:duration-700'
							leaveFrom='translate-x-0'
							leaveTo='translate-x-full'
						>
							<div className={`w-screen ${size}`}>
								<div className='h-full flex flex-col bg-white shadow-xl'>
									{children}
								</div>
							</div>
						</Transition.Child>
					</div>
				</div>
				{/* put other sliderOver here? */}
			</Dialog>
		</Transition.Root>
	)
}

function Title({ children, handleClose }) {
	return (
		<div className='py-6 px-4 bg-indigo-700 sm:px-6 justify-start'>
			<div className='flex items-center justify-between'>
				<Dialog.Title className='text-lg font-medium text-white'>
					{children}
				</Dialog.Title>
				<div className='ml-3 h-7 flex items-center'>
					<button
						type='button'
						className='bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white'
						onClick={() => handleClose(false)}
					>
						<span className='sr-only'>Close panel</span>
						<XIcon className='h-6 w-6' aria-hidden='true' />
					</button>
				</div>
			</div>
			{/* <div className="mt-1">
                <p className="text-sm text-indigo-300">
                  Lorem, ipsum dolor sit amet consectetur adipisicing elit
                  aliquam ad hic recusandae soluta.
                </p>
              </div> */}
		</div>
	)
}

function Content({ children }) {
	return (
		<div className='min-h-0 flex-1 flex flex-col py-6 overflow-y-scroll'>
			<div className='relative flex-1 px-4 sm:px-6'>{children}</div>
		</div>
	)
}

function Actions({ children }) {
	return (
		<div className='flex-shrink-0 px-4 py-4 flex border-t items-center justify-between'>
			{children}
		</div>
	)
}

SlideOver.Title = Title
SlideOver.Content = Content
SlideOver.Actions = Actions

export default SlideOver
