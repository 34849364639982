import * as React from 'react'

export default function InputText({
	id,
	onChange,
	value,
	label,
	helperText,
	required,
}) {
	return (
		<React.Fragment>
			<label htmlFor={id} className='block text-sm font-medium text-gray-700'>
				{label ? label : ''}
				{required ? ' *' : ''}
			</label>
			<div className='mt-1'>
				<input
					id={id}
					name={id}
					type='text'
					autoComplete='off'
					className='shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
					value={value}
					onChange={onChange}
				/>
			</div>
			<p className='mt-2 text-sm text-gray-500'>
				{helperText ? helperText : ''}
			</p>
		</React.Fragment>
	)
}
