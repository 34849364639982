import { PhotographIcon } from '@heroicons/react/outline'

import {
	ChevronRightIcon,
	QuestionMarkCircleIcon,
} from '@heroicons/react/solid'

import EpisodeReadyState from './EpisodeReadyState'

function EpisodeItem({ children, episode }) {
	/*
  Image
  Ready state
  Publish Date (null on top, asc)
  Title
  Live Y / N
  Quiz Y / N
  Edit button
  Delete button (soft delete)
  */

	return (
		<div className='rounded bg-gray-50 overflow-hidden shadow'>
			<div className='block hover:bg-gray-100'>
				<div className='flex items-center'>
					{/* px-4 py-4 sm:px-6 */}
					<div className='min-w-0 flex-1 flex items-center'>
						<div className='flex-shrink-0 h-24 w-48'>
							{episode.coverImage ? (
								<img
									className='h-24 w-48 object-cover'
									src={episode.coverImage}
									alt=''
								/>
							) : (
								<div className='h-24 w-48 bg-gray-300 flex justify-center items-center text-sm'>
									<PhotographIcon className='h-6 w-6 text-gray-600' />
								</div>
							)}
						</div>
						<div className='min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4'>
							<div>
								<p className='text-sm font-medium text-indigo-600 truncate'>
									{episode.title}
								</p>
								<p className='mt-2 flex items-center text-sm text-gray-500'>
									<span className='truncate'>{episode.description}</span>
								</p>
								{episode.hasQuiz ? (
									<p className='mt-2 flex items-center text-sm text-gray-500'>
										<QuestionMarkCircleIcon
											className='flex-shrink-0 mr-1.5 h-5 w-5 text-indigo-400'
											aria-hidden='true'
										/>
										<span className='truncate'>Har quiz</span>
									</p>
								) : null}
							</div>
							<div className='hidden md:block'>
								<div>
									<EpisodeReadyState episode={episode} />
									{episode.publishDateTime ? (
										<p className='text-sm text-gray-500'>
											{episode?.readyState === 'live'
												? 'Ble publisert '
												: 'Har publiseringsdato '}
											<time
												className='text-gray-900'
												dateTime={episode.publishDateTime}
											>
												{new Date(episode?.publishDateTime)?.toLocaleString(
													'nb-NO',
													{
														weekday: 'long',
														year: 'numeric',
														month: 'long',
														day: 'numeric',
														hour: '2-digit',
														minute: '2-digit',
													}
												)}
											</time>
										</p>
									) : (
										<p className='text-sm text-gray-500'>
											Episoden har ikke publiseringsdato
										</p>
									)}
								</div>
							</div>
						</div>
					</div>
					<div className='mr-5'>
						{children ? (
							children
						) : (
							<ChevronRightIcon
								className='h-5 w-5 text-gray-400'
								aria-hidden='true'
							/>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

function Actions({ children }) {
	return children
}

EpisodeItem.Actions = Actions

export default EpisodeItem
