import * as React from 'react'

import {
	CheckCircleIcon,
	ChevronRightIcon,
	ExclamationIcon,
} from '@heroicons/react/solid'

import {
	ClockIcon,
	PauseIcon,
	CollectionIcon,
	ChatAltIcon,
	PhotographIcon,
} from '@heroicons/react/outline'

export default function ContentSetItem({ contentSet }) {
	const numberOfEpisodes = contentSet?.contentItems?.episodeCount
	const numberOfGroups = contentSet?.contentItems?.contentSetCount

	return (
		<div className='rounded bg-gray-50 overflow-hidden shadow'>
			<div className='block hover:bg-gray-100'>
				{/*
  Image
  Ready state
  Publish Date (null on top, asc)
  Title
  Live Y / N
  Quiz Y / N
  Edit button
  Delete button (soft delete)
  */}
				<div className='flex items-center '>
					{/* px-4 py-4 sm:px-6 */}
					<div className='min-w-0 flex-1 flex items-center'>
						<div className='flex-shrink-0'>
							{contentSet.coverImage ? (
								<img
									className='h-24 w-48 object-cover'
									src={contentSet.coverImage}
									alt=''
								/>
							) : (
								<div className='h-24 w-48 bg-gray-300 flex justify-center items-center text-sm'>
									<PhotographIcon className='h-6 w-6 text-gray-600' />
								</div>
							)}
						</div>
						<div className='min-w-0 flex-1 px-4 md:grid md:grid-cols-2 md:gap-4'>
							<div>
								<p className='text-sm font-medium text-indigo-600 truncate'>
									{contentSet.title}
								</p>
								<p className='mt-2 flex items-center text-sm text-gray-500'>
									<span className='truncate'>{contentSet.description}</span>
								</p>
								{/* {group.hasQuiz ? (
      <p className="mt-2 flex items-center text-sm text-gray-500">
        <QuestionMarkCircleIcon
          className="flex-shrink-0 mr-1.5 h-5 w-5 text-gray-400"
          aria-hidden="true"
        />
        <span className="truncate">Har quiz</span>
      </p>
    ) : null} */}
								<p className='mt-2 flex items-center text-sm text-gray-500'>
									{numberOfEpisodes ? (
										<React.Fragment>
											<ChatAltIcon
												className='flex-shrink-0 mr-1.5 h-5 w-5 text-indigo-400'
												aria-hidden='true'
											/>
											<span className='truncate mr-4'>
												{numberOfEpisodes}{' '}
												{numberOfEpisodes === 1 ? 'episode' : 'episoder'}
											</span>
										</React.Fragment>
									) : null}
									{numberOfGroups ? (
										<React.Fragment>
											<CollectionIcon
												className='flex-shrink-0 mr-1.5 h-5 w-5 text-indigo-400'
												aria-hidden='true'
											/>
											<span className='truncate'>
												{numberOfGroups} innholdssett
											</span>
										</React.Fragment>
									) : null}
								</p>
							</div>
							<div className='hidden md:block'>
								<div>
									<ReadyState group={contentSet} />
									<p className='text-sm text-gray-500'>
										{contentSet?.readyState === 'live'
											? 'Ble publisert '
											: 'Har publiseringsdato '}
										<time
											className='text-gray-900'
											dateTime={contentSet.publishedDate}
										>
											{contentSet?.publishedDate?.toLocaleString('nb-NO', {
												weekday: 'long',
												year: 'numeric',
												month: 'long',
												day: 'numeric',
											})}
										</time>
									</p>
								</div>
							</div>
						</div>
					</div>
					<div className='mr-5'>
						<ChevronRightIcon
							className='h-5 w-5 text-gray-400'
							aria-hidden='true'
						/>
					</div>
				</div>
			</div>
		</div>
	)
}

function ReadyState({ group }) {
	if (group?.readyState === 'live') {
		return (
			<p className='my-2 flex items-center text-sm text-gray-500'>
				<CheckCircleIcon
					className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400'
					aria-hidden='true'
				/>
				Innholdssettet er live.
			</p>
		)
	}

	if (group?.readyState === 'ready') {
		return (
			<p className='my-2 flex items-center text-sm text-gray-500'>
				<ClockIcon
					className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400'
					aria-hidden='true'
				/>
				Innholdssettet er klart og venter på å gå live.
			</p>
		)
	}

	if (group?.readyState === 'awaitingPublication') {
		return (
			<p className='my-2 flex items-center text-sm text-gray-500'>
				<PauseIcon
					className='flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400'
					aria-hidden='true'
				/>
				Innholdssettet er fullstendig, men det er ikke godkjent for publisering.
			</p>
		)
	}

	// if (episode?.readyState === "draft") {
	return (
		<p className='my-2 flex items-center text-sm text-gray-500'>
			<ExclamationIcon
				className='flex-shrink-0 mr-1.5 h-5 w-5 text-red-400'
				aria-hidden='true'
			/>
			Innholdssettet er ufullstendig og kan ikke publiseres.
		</p>
	)
}
