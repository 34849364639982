import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/solid'

import { ClockIcon, PauseIcon } from '@heroicons/react/outline'

function EpisodeReadyState({ episode }) {
	if (episode?.readyState === 'live') {
		return (
			<p className='my-2 flex items-center text-sm text-gray-500'>
				<CheckCircleIcon
					className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400'
					aria-hidden='true'
				/>
				Episoden er live.
			</p>
		)
	}

	if (episode?.readyState === 'ready') {
		return (
			<p className='my-2 flex items-center text-sm text-gray-500'>
				<ClockIcon
					className='flex-shrink-0 mr-1.5 h-5 w-5 text-green-400'
					aria-hidden='true'
				/>
				Episoden er klar og venter på å gå live.
			</p>
		)
	}

	if (episode?.readyState === 'awaitingPublication') {
		return (
			<p className='my-2 flex items-center text-sm text-gray-500'>
				<PauseIcon
					className='flex-shrink-0 mr-1.5 h-5 w-5 text-yellow-400'
					aria-hidden='true'
				/>
				Episoden er fullstendig, men den er ikke godkjent for publisering.
			</p>
		)
	}

	// if (episode?.readyState == "draft") {
	return (
		<p className='my-2 flex items-center text-sm text-gray-500'>
			<ExclamationIcon
				className='flex-shrink-0 mr-1.5 h-5 w-5 text-red-400'
				aria-hidden='true'
			/>
			Episoden er ufullstendig og kan ikke publiseres.
		</p>
	)
}

export default EpisodeReadyState
